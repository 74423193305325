import {
  Autocomplete,
  Backdrop,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Yup from 'yup';
import { isArray, isEmpty } from 'lodash';
import InputMask from 'react-input-mask';
import { Add } from '@mui/icons-material';
import { camelCase } from 'change-case';
import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useValueSet } from 'src/@nicheaim/fhir-react';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { convertValueToValueSetAll } from 'src/sections/crs/common/common-utils';
import { ValueSetWrapper, WrappedValueSet } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { RelatedPerson } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useLocales from 'src/hooks/useLocales';
import AddAddressLoading from 'src/sections/crs/referral/components/workflow-step/AddressChecklistItem/AddAddressLoading';

type FormValue = {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  emailUse: string;
  email: string;
  phoneUse: string;
  relationshipCode: any[];
  street: string;
  street2: string;
  city: string;
  district: string;
  phone: string;
  state: string;
  zipCode: string;
};

type EditModeProps = {
  title: string;
  patient: WrappedPatient | null;
  relatedPerson: RelatedPerson | null;
  open: boolean;
  onClose: VoidFunction;
  handleRelatedPerson: (data: any) => Promise<any>;
  relationshipValueSet: WrappedValueSet | null;
};

export default function RelatedPersonAction({
  title,
  patient,
  relatedPerson,
  open,
  onClose,
  handleRelatedPerson,
  relationshipValueSet,
}: EditModeProps) {
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [address, setAddress] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { i18n } = useLocales();

  const [genderCategories] = useValueSet('us-core-birthsex', { map: ValueSetWrapper });

  const EventSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .matches(/^[a-zA-Z\s]+$/, 'First Name cannot contain numbers'),
    lastName: Yup.string()
      .required('Last Name is required')
      .matches(/^[a-zA-Z\s-]+$/, 'Last Name cannot contain numbers'),
    email: Yup.string().email('Email must be a valid email address'),
    phone: Yup.string()
      .nullable()
      .notRequired()
      .test('test-phone','Please enter a valid phone number', function (value) {
        if (!value) return true;
        const isValidPhone = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(value);
        if (!isValidPhone) {
          setValue?.('phoneUse', ''); 
        }
        return isValidPhone;
      }),
    phoneUse: Yup.string()
      .nullable()
      .notRequired()
      .when('phone', {
        is: (phone) => /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(phone || ''),
        then: Yup.string().required('Phone Use is required'),
      }),
  });

  const defaultValues = useMemo(
    () =>
    ({
      id: relatedPerson?.id || '',
      lastName: relatedPerson?.name?.[0]?.family || '',
      firstName: relatedPerson?.name?.[0]?.given?.[0] || '',
      middleName: relatedPerson?.name?.[0]?.given?.[1] || '',
      gender: relatedPerson?.gender || '',
      emailUse: relatedPerson?.telecom?.filter((e) => e.system === 'email')?.[0]?.use || '',
      email: relatedPerson?.telecom?.filter((e) => e.system === 'email')?.[0]?.value || '',
      phoneUse: relatedPerson?.telecom?.filter((e) => e.system === 'phone')?.[0]?.use || '',
      phone: relatedPerson?.telecom?.filter((e) => e.system === 'phone')?.[0]?.value || '',
      relationshipCode: relatedPerson?.relationship?.map((e) => e?.coding?.[0]) ?? [],
      street: relatedPerson?.address?.[0]?.line?.[0] || '',
      street2: relatedPerson?.address?.[0]?.line?.[1] || '',
      city: relatedPerson?.address?.[0]?.city || '',
      district: relatedPerson?.address?.[0]?.district || '',
      state: relatedPerson?.address?.[0]?.state || '',
      zipCode: relatedPerson?.address?.[0]?.postalCode || '',
    } as FormValue),
    [relatedPerson]
  );

  const methods = useForm({ resolver: yupResolver(EventSchema), defaultValues });

  const { reset, control, handleSubmit, setValue } = methods;

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [open]);

  useEffect(() => {
    reset(defaultValues);
  }, [relatedPerson]);

  const saveChanges = async (data: any) => {
    setValue('street', data.line[0]);
    setValue('street2', data.line[1]);
    setValue('city', data.city);
    setValue('district', data.district);
    setValue('zipCode', data.postalCode);
    setValue('state', data.state);
    setAddress(data);
    setOpenAddAddress(false);
  };

  const onSubmit = async (data: FormValue) => {

    if (relationshipValueSet === null) setOpenBackdrop(true);

    const email: any = {
      rank: 1,
      use: data?.emailUse,
      system: 'email',
      value: data?.email,
    };

    const phone: any = {
      rank: 1,
      use: data?.phoneUse,
      system: 'phone',
      value: data?.phone,
    };

    const gender: any = data?.gender;

    const getAddress = !isEmpty(address) ? address : relatedPerson?.address;

    const relationship = data?.relationshipCode?.map((e) => ({
      coding: [
        {
          ...convertValueToValueSetAll(e?.code, relationshipValueSet),
        },
      ],
      text: convertValueToValueSetAll(e?.code, relationshipValueSet)?.display,
    }));

    const payload = {
      // Add id to object only of it's in data(updating an existing related person)
      ...(data?.id && { id: data?.id }),
      resourceType: 'RelatedPerson',
      name: [
        {
          // add family property to name only if family exists and is not null
          ...(data?.lastName && {
            family: data?.lastName,
          }),
          // add given property to name only if given exists and is not null
          ...(data?.firstName && {
            given: [data?.firstName, ...(!isEmpty(data?.middleName) ? [data?.middleName] : [])],
          }),
        },
      ],
      // add telecom only if phone or email exist
      ...((phone?.value || email?.value) && {
        telecom: [...(phone?.value ? [phone] : []), ...(email?.value ? [email] : [])],
      }),
      ...(getAddress && { address: isArray(getAddress) ? getAddress : [getAddress] }),
      ...(gender && { gender: gender }),
      ...(relationship?.length > 0 ? { relationship: relationship } : null ),
      patient: { reference: `${patient?.resourceType}/${patient?.id}` }
    };

    setAddress({});

    handleRelatedPerson(payload);

    reset(defaultValues);

    setOpenBackdrop(false);

    onClose();
  };

  if (!open) return null;

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth="md">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>{title}</DialogTitle>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Card sx={{ p: 2 }}>
                <Typography
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  variant="button"
                  sx={{ textTransform: 'uppercase' }}
                >
                  {i18n('patients.details.relatedPersons.personalDetails', 'crs')}
                </Typography>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                  <Grid item xs={4}>
                    <RHFTextField name="firstName" label={`${i18n('patients.firstName', 'crs')}*`} />
                  </Grid>
                  <Grid item xs={4}>
                    <RHFTextField name="middleName" label={i18n('patients.middleName', 'crs')} />
                  </Grid>
                  <Grid item xs={4}>
                    <RHFTextField name="lastName" label={`${i18n('patients.lastName', 'crs')}*`} />
                  </Grid>
                  <Grid item xs={4}>
                    <RHFSelect name="gender" label={i18n('patients.details.relatedPersons.gender', 'crs')} fullWidth={true}>
                      <MenuItem disabled />
                      {genderCategories?.asList().map((option) => (
                        <MenuItem key={option.code} value={camelCase(option.display || '')}>
                          {option.display}
                        </MenuItem>
                      ))}
                    </RHFSelect>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ p: 2 }}>
                <Typography
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  variant="button"
                  sx={{ textTransform: 'uppercase' }}
                >
                  {relationshipValueSet ? 
                    `${i18n('patients.details.relatedPersons.titleAddRelatedPersons', 'crs')} Details` : 
                    "Contact Details"}
                </Typography>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                  {relationshipValueSet && (
                    <Grid item xs={12}>
                      <Controller
                        name="relationshipCode"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            multiple
                            freeSolo
                            onChange={(event, newValue) => field.onChange(newValue)}
                            options={relationshipValueSet?.asListAll()}
                            getOptionLabel={(option) => option?.display}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  {...getTagProps({ index })}
                                  key={option?.code}
                                  size="small"
                                  label={option?.display}
                                />
                              ))
                            }
                            renderInput={(params) => 
                              <TextField 
                                label={i18n('patients.details.relatedPersons.relationship', 'crs')}
                                {...params}  
                              />
                            }
                          />
                        )}
                      />
                    </Grid>
                  )}

                  <Grid item xs={4}>
                    <RHFSelect name="emailUse" label={i18n('patients.details.relatedPersons.emailUse', 'crs')} fullWidth>
                      <MenuItem value="home">Home</MenuItem>
                      <MenuItem value="work">Work</MenuItem>
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={8}>
                    <RHFTextField name="email" label={i18n('patients.email', 'crs')} fullWidth />
                  </Grid>
                  <Grid item xs={4}>
                    <RHFSelect name="phoneUse" label={i18n('patients.details.relatedPersons.phoneUse', 'crs')} fullWidth>
                      <MenuItem value="home">Home</MenuItem>
                      <MenuItem value="work">Work</MenuItem>
                      <MenuItem value="mobile">Mobile</MenuItem>
                    </RHFSelect>
                  </Grid>
                  <Grid item xs={8}>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <InputMask mask="(999) 999-9999" value={value} onChange={onChange}>
                          {(inputProps: any) => (
                            <TextField
                              label={i18n('patients.phone', 'crs')}
                              {...inputProps}
                              fullWidth
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        </InputMask>
                      )}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ p: 2 }}>
                <Typography
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                  variant="button"
                  sx={{ textTransform: 'uppercase' }}
                >
                  {i18n('patients.details.relatedPersons.addressDetails', 'crs')}
                  <Button
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => setOpenAddAddress(true)}
                  >
                    Add
                  </Button>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <RHFTextField name="street" label={i18n('patients.details.relatedPersons.street', 'crs')} fullWidth disabled />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFTextField name="street2" label={i18n('patients.details.relatedPersons.streetTwo', 'crs')} fullWidth disabled />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2}>
                      <RHFTextField name="city" label={i18n('patients.details.relatedPersons.city', 'crs')} fullWidth disabled />
                      <RHFTextField name="district" label={i18n('patients.details.relatedPersons.county', 'crs')} fullWidth disabled />
                      <RHFTextField name="state" label={i18n('patients.details.relatedPersons.state', 'crs')} fullWidth disabled />
                      <RHFTextField name="zipCode" label={i18n('patients.details.relatedPersons.zipCode', 'crs')} fullWidth disabled />
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} justifyContent="center" sx={{ pb: 2 }}>
            <Button color="primary" variant="outlined" onClick={onClose}>
              {i18n('cancel')}
            </Button>
            <Button color="primary" variant="contained" type="submit">
              {i18n('save')}
            </Button>
          </Stack>
        </FormProvider>
      </Dialog>
      <Dialog open={openAddAddress} fullWidth maxWidth="md">
        <DialogTitle> Add Address</DialogTitle>
        <Card sx={{ p: 2, mt: 2 }}>
          <AddAddressLoading isEditable handleClose={() => setOpenAddAddress(false)} handleSave={saveChanges} />
        </Card>
      </Dialog>
    </>
  );
}