import { 
  Button, 
  Card, 
  CardContent, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  FormControl, 
  FormControlLabel,
  Grid, 
  Radio, 
  RadioGroup, 
  TextField, 
  Typography 
} from "@mui/material";
import _ from 'lodash';
import { Link } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import useLocales from "src/hooks/useLocales";
import { getIdentifier } from "src/utils/address";
import { workflowInstanceDataStep } from "src/sections/crs/helpers/workflow";

const AddressInCountyDialog = ({
  isOpen,
  handleClose,
  handleSave,
  saveCountyAddress,
  addressList,
  workflowInstance,
  handleOpenFromIsCounty
}: any) => {
  const { i18n } = useLocales();
  const [openDialog, setOpenDialog] = useState(isOpen);
  const [notes, setNotes] = useState<string>('');
  const [inCounty, setInCounty] = useState<string>('out');
  const [savePayload, setSavePayload] = useState({ county: '', notes: '' });

  useEffect(() => {
    setOpenDialog(isOpen);
  }, [isOpen]);

  const getAddressSaved = workflowInstanceDataStep(
    workflowInstance, "VALIDATE_HOME_ADDRESS_REFERRAL_ITEM.modifiedFields"
  );

  const getValidAddress = useMemo(() => {
    if (!addressList) return;
    const sortedAddresses = [...addressList].sort((a, b) => 
      b.postalCode.localeCompare(a.postalCode)
    );

    const addressIdentifierWorkflowData = getIdentifier(getAddressSaved);
  
    const validAddress = 
      sortedAddresses.find((address: any) => 
        addressIdentifierWorkflowData && getIdentifier(address) === addressIdentifierWorkflowData
      ) || 
      sortedAddresses.find((address: any) => 
        address?.extension?.[0]?.extension?.some((a: any) => a.url === 'valid' && a.valueBoolean)
      );

    return validAddress;
  },[getAddressSaved, addressList]);

  const handleNotesChange = (e: any) => {
    setNotes(e.target.value);
    setSavePayload((prev) => ({ county: prev.county, notes: e.target.value }));
  };

  const handleInCountyChange = (e: any) => {
    setInCounty(e.target.value);
    setSavePayload((prev) => ({ county: e.target.value, notes: prev.notes }));
  };

  const markAddress = () => {
    if (!addressList || !getValidAddress) return;

    const updatedAddresses = addressList.map((address) => {
      if (getIdentifier(getValidAddress) !== getIdentifier(address)) return address;

      const extensions = address.extension?.[0]?.extension || [];
      
      const updateOrAddExtension = (url: string, value: any) => {
        const index = extensions.findIndex((ext: any) => ext.url === url);
        const newExtension = { url, ...value };

        if (index !== -1) extensions[index] = newExtension;
        else extensions.push(newExtension);
      };

      updateOrAddExtension('inCounty', { valueBoolean: inCounty === 'in' });
      updateOrAddExtension('inCountyNotes', { valueString: notes });

      return { ...address, extension: [{ ...address.extension?.[0], extension: extensions }] };
    });

    saveCountyAddress(savePayload);
    handleSave(updatedAddresses);
  };

  const showAddress = useMemo(() => (getValidAddress &&
    Object.keys(getValidAddress).length > 0 &&
    getValidAddress?.line &&
    _.isArray(getValidAddress?.line))
    ? getValidAddress?.line?.[0]
    : null,
  [getValidAddress]);

  return (
    <Dialog open={openDialog}>
      <DialogTitle>
        <Typography variant="subtitle1">Fix Invalid Address</Typography>
      </DialogTitle>

      <DialogContent>
        {showAddress ? (
          <Card>
            <CardContent>
              <Typography variant="body2">
                {showAddress} is in or out the county?
              </Typography>

              <FormControl fullWidth>
                <RadioGroup
                  row
                  name="inCounty"
                  defaultValue={inCounty}
                  onChange={handleInCountyChange}
                >
                  <Grid container>
                    <Grid item sm={6}>
                      <FormControlLabel value="in" control={<Radio />} label="In County" />
                    </Grid>
                    <Grid item sm={6}>
                      <FormControlLabel value="out" control={<Radio />} label="Out of County" />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>

              <Grid container>
                <Grid item sm={12}>
                  <FormControl fullWidth>
                    <Typography>Note</Typography>
                    <TextField
                      id="note"
                      variant="outlined"
                      name="countyNotes"
                      onChange={handleNotesChange}
                      multiline
                      fullWidth
                      rows={4}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              
              <Grid container>
                <Grid item sm={12} sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <Link
                      component="button"
                      onClick={handleOpenFromIsCounty}
                      sx={{ color: 'black', fontSize: 12 }}
                      >
                      ** &nbsp; Fix Invalid Address
                      </Link>
                  </FormControl>
                </Grid>
              </Grid>
              
              <Grid container spacing={3} justifyContent="center" sx={{ mt: getAddressSaved ? 0 : 1 }}>
                <Grid item >
                  <Button variant="contained" onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={markAddress}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent>
              <Typography>
                This {i18n('patients.title', 'crs')} has no valid address, please select or add a valid address to continue
              </Typography>
              <Button variant="contained" onClick={handleClose}>
                Cancel
              </Button>
            </CardContent>
          </Card>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AddressInCountyDialog;