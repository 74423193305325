import { getFhirIdFromEntity } from 'src/utils/fhir';
import { useEffect, useMemo, useState } from 'react';
import { QuestionnaireAnswerMap, ReportResources } from 'src/@types/nat/report';
import { TaskWrapper } from 'src/@nicheaim/fhir-base/wrappers/Task';
import { PatientWrapper, WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { 
  Bundle, 
  MeasureReport, 
  Organization, 
  Patient, 
  Practitioner, 
  Questionnaire, 
  QuestionnaireResponse, 
  RelatedPerson,
  ServiceRequest, 
  Task, 
  TaskInput, 
  TaskOutput 
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import axiosFhirInstance from 'src/application/adapters/out/repositories/axiosFhirInstance';
import { ObservationWrapper, WrappedObservation } from 'src/@nicheaim/fhir-base/wrappers/Observation';
import { useObservations, usePlanDefinition, useServiceRequest, useTasks } from 'src/@nicheaim/fhir-react';
import { PlanDefinitionWrapper, WrappedPlanDefinition } from "src/@nicheaim/fhir-base/wrappers/PlanDefinition";
import { ServiceRequestWrapper, WrappedServiceRequest } from "src/@nicheaim/fhir-base/wrappers/ServiceRequest";
import { getUserByEmail } from 'src/services/api/users';
import { taskService } from "src/nat/task/services";

const useTaskServiceRequestQuestionnaire = ( taskId: string ): { 
  patient: WrappedPatient | undefined, 
  report: ReportResources[] | undefined, 
  planDefinition: WrappedPlanDefinition | null, 
  serviceRequest: WrappedServiceRequest | null,
  observations: WrappedObservation[] | undefined;
  updateServiceRequest: any,
  refreshServiceRequest: any,
  isLoading: boolean,
} => {

  const [ patientFromTask, setPatientFromTask ] = useState<Patient | undefined>(); 
  const [ report, setReport ] = useState<ReportResources[] | undefined>(); 
  const [ serviceRequestFromTask, setServiceRequestFromTask ] = useState<ServiceRequest | undefined>(); 
  const [ isLoadingReport, setIsLoadingReport ] = useState(false); 

  const taskPatientServiceRequest = async () => (( await axiosFhirInstance.get(
    `Task?_id=${taskId}&code=completed-nat-assessment&_include:iterate=Task:patient:Patient&_include:iterate=Task:based-on:ServiceRequest`,
  ))?.data as Bundle<Task | Patient | ServiceRequest>)?.
  entry?.map((entry) => entry?.resource);  

  useEffect(() => {
    const taskResources = async () => {
      if(taskId) {
        const getResources = await taskPatientServiceRequest();
        setPatientFromTask(getResources?.find((resource): resource is Patient => resource?.resourceType === 'Patient'));
        setServiceRequestFromTask(getResources?.find((resource): resource is ServiceRequest => resource?.resourceType === 'ServiceRequest'));
      }
    };
    taskResources();
  }, [taskId]);
  
  const [ serviceRequest,{ 
    update: updateServiceRequest, 
    refresh: refreshServiceRequest,
    isLoading: isLoadingServiceRequest,
  }] =  useServiceRequest(serviceRequestFromTask?.id, {
    map: ServiceRequestWrapper,
    autofetch: !!serviceRequestFromTask
  });

  const patient = patientFromTask && PatientWrapper(patientFromTask);

  const planDefinitionId = useMemo(() => 
    getFhirIdFromEntity(serviceRequestFromTask?.instantiatesCanonical?.[0] ?? ''), [serviceRequest]
  );

  const [ planDefinition ] =  usePlanDefinition(planDefinitionId, {
    map: PlanDefinitionWrapper,
    autofetch: !!planDefinitionId,
  });

  const [ tasks, { isLoading: isLoadingTasks }  ] = useTasks({
    filter: {
      'based-on': serviceRequestFromTask?.id,
    },
    map: TaskWrapper,
    autofetch: !!serviceRequestFromTask
  });

  useEffect(() => {
    const fetchData = async () => {
      const data = await getQuestionnaireAndResponses();
      setReport(data);
    };
    fetchData();
  }, [serviceRequestFromTask, tasks]);

  const getQuestionnaireAndResponses = async (): Promise<any> => {
    try {
      setIsLoadingReport(true);
      const report = await Promise.all(tasks?.map( async (item) => {
        const questionnaireFromInput: string[] = item?.input?.
          filter((x1: TaskInput) => x1?.valueUri?.includes('Questionnaire')).
            map((x2) => getFhirIdFromEntity(x2?.valueUri ?? '') ?? '') ?? [];
  
        const questionnaireResponseFromOutput: string[] = item?.output?.
          filter((x1: TaskOutput) => x1?.valueReference?.type === 'QuestionnaireResponse').
            map((x2) => getFhirIdFromEntity(x2?.valueReference?.reference ?? '') ?? '') ?? [];
            
        const measureReportFromOutput: string = item?.output?.
          find((x1: TaskOutput) => x1?.type?.coding?.[0]?.code === 'measureReport')?.valueUri ?? '';
  
        const questionnaire = questionnaireFromInput?.length ? ((await axiosFhirInstance.get(
            `Questionnaire?_id=${questionnaireFromInput?.join(',')}`,
          ))?.data as Bundle<Questionnaire>)?.
        entry?.map((entry) => entry?.resource) : [];

        const questionnaireAnswerMap = questionnaireFromInput?.length ? (await taskService.getQuestionnaireAnswerMap(
          questionnaireFromInput))?.data as QuestionnaireAnswerMap[] : [];
  
        const questionnaireResponse = questionnaireResponseFromOutput?.length ? ((await axiosFhirInstance.get(
            `QuestionnaireResponse?_id=${questionnaireResponseFromOutput?.join(',')}`,
          ))?.data as Bundle<QuestionnaireResponse>)?.
        entry?.map((entry) => entry?.resource) : [];
  
        const measureReport = measureReportFromOutput ? (await axiosFhirInstance.get(
          `MeasureReport/${getFhirIdFromEntity(measureReportFromOutput) ?? ''}`,
        ))?.data as MeasureReport : undefined;
  
        const ownerTask = item?.owner && (await axiosFhirInstance.get(
          `${item?.owner?.reference}`,
        ))?.data as RelatedPerson | Patient;
  
        const requesterTaskResource = item?.requester && (await axiosFhirInstance.get(
          `${item?.requester?.reference}`,
        ))?.data as Practitioner | Organization;

        let requesterTask;
        if(requesterTaskResource?.resourceType === 'Organization'){
          const userEmailFromTask = item?.identifier?.find((item) => item?.type?.text === 'User')?.value;
          const userFromMetaTag = item?.meta?.tag?.find((e) => e?.code === "ph-created-by")?.display;
          const getUser = await getUserByEmail(userEmailFromTask ?? userFromMetaTag ?? "");
          requesterTask = {
            resource: requesterTaskResource, 
            email: userEmailFromTask ?? userFromMetaTag ?? "",
            name: getUser?.id ? `${getUser?.firstName} ${getUser?.lastName}` : ""
          };
        }else {
          requesterTask = { resource: requesterTaskResource }
        }
        
        return {
          task: item,
          ownerTask: ownerTask,
          requesterTask: requesterTask,
          measureReport: measureReport,
          questionnaire: questionnaire, 
          questionnaireResponse: questionnaireResponse, 
          questionnaireAnswerMap: questionnaireAnswerMap
        }
      }));
      return report;
    } catch (error) {}
    finally{
      setIsLoadingReport(false);
    }
  };  

  const [ observations, { isLoading: isLoadingObservations } ] = useObservations({ 
    filter: { subject: patient?.id }, 
    map: ObservationWrapper,
    autofetch: !!patient
  });

  const isLoading = useMemo(() => 
    (serviceRequestFromTask !== undefined ? isLoadingServiceRequest : false) || 
    (serviceRequestFromTask !== undefined ? isLoadingTasks : false) || 
    (patient !== undefined ? isLoadingObservations : false ) || 
    isLoadingReport
  ,[
    serviceRequestFromTask,
    patient,
    isLoadingServiceRequest,
    isLoadingTasks, 
    isLoadingObservations,
    isLoadingReport
  ]);

  return { 
    patient, 
    report ,
    planDefinition, 
    serviceRequest, 
    observations,
    updateServiceRequest,
    refreshServiceRequest,
    isLoading
  }
}

export default useTaskServiceRequestQuestionnaire;