import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Divider, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDialogStore } from 'src/stores/dialog';

type Props = {
  open: boolean;
  url: string | null;
  onCancel: VoidFunction;
};

const AssessmentFrame = ({ open, url, onCancel }: Props) => {
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [disabledButton, setDisabledButton] = useState(true);
  const [note, setNote] = useState(0);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const observerRef = useRef<MutationObserver | null>(null);
  const { confirmDialog } = useDialogStore();

  function showLoader() {
    setOpenBackdrop(true);
  }

  function hideLoader() {
    setOpenBackdrop(false);
  }

  function onIframeLoad() {
    const iframeWindow = iframeRef.current?.contentWindow;
    console.log('iframeWindow',iframeWindow);
    if (!iframeWindow) return;

    hideLoader();
    setDisabledButton(false);
    // iframeWindow.onbeforeunload = showLoader;

    try {
      const iframeDocument = iframeWindow.document;
      console.log('iframeDocument',iframeDocument);

      const findButtonsSetNote = () => {
        const submitButton = iframeDocument.querySelector<HTMLInputElement>('#ls-button-submit[value="movesubmit"]');
        console.log('submitButton',submitButton);
        const nextButton = iframeDocument.querySelector<HTMLInputElement>('#ls-button-submit[value="movenext"]');
        console.log('nextButton',nextButton);
        const isDisabled = submitButton?.disabled || submitButton?.getAttribute("aria-disabled") === "true";
        console.log('isDisabled',isDisabled);
        const isSameUrl = url === iframeWindow.location.href;
        console.log('isSameUrl',isSameUrl);

        setDisabledButton(isDisabled ?? true);

        if (submitButton) setNote(1);
        if (nextButton && isSameUrl) setNote(2);
        if (nextButton && !isSameUrl) setNote(3);
      };

      findButtonsSetNote();

      observerRef.current?.disconnect();

      observerRef.current = new MutationObserver(() => {
        findButtonsSetNote();
      });

      observerRef.current.observe(iframeDocument.body, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ["disabled", "aria-disabled"],
      });

    } catch (error) {
      console.log('AssessmentFrame error',error);
    }
  }

  const onClose = async () => {

    if (note === 2 || note === 0) {
      onCancel();
      return;
    }

    const confirms = await confirmDialog({
      title: "Confirm",
      description: (
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Your changes may not have been saved, do you want to continue?
        </Typography>
      ),
    });

    if (confirms) {
      onCancel();
    }
  };

  useEffect(() => {
    if (open) {
      setOpenBackdrop(true);
      setDisabledButton(true);
      setNote(0);
    }

    return () => {
      observerRef.current?.disconnect();
    };
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      PaperProps={{
        sx: {
          width: '100%',
          height: `calc(100vh - 200px)`,
          backgroundColor: '#f4f6f8'
        },
      }}
    >
      <DialogTitle sx={{ pb: 2 }}>Assessment</DialogTitle>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {url ? (
        <iframe
          src={url}
          ref={iframeRef}
          title="Assessment"
          style={{ border: 0, width: '100%', height: '100%' }}
          onLoad={onIframeLoad}
        />
      ) : null}
      <Divider />
      <DialogActions sx={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        <Button 
          disabled={disabledButton}
          type="button" 
          onClick={onClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssessmentFrame;