import { Address } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';

export const formatUSAddress = (address: Address | null | undefined): string => {
  let formattedAddress = '';

  if (address?.line && address?.line?.length > 0) {
    formattedAddress += address.line.join(', ') + '\n';
  }

  if (address?.city || address?.state || address?.postalCode) {
    formattedAddress +=
      `${address?.city || ''}${address?.city && address?.state ? ', ' : ''}${
        address?.state || ''
      } ${address?.postalCode || ''}`.trim() + '\n';
  }

  if (address?.country) {
    formattedAddress += address.country;
  }

  return formattedAddress.trim();
};

export const getIdentifier = (address?: any) => 
  address?.extension?.[0]?.extension?.find((a: any) => a.url === 'identifier')?.valueString;