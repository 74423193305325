import * as Yup from 'yup';
import agent from 'src/api/agent';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { isArray } from 'lodash';

type FormValues = {
  reason: string;
  reasonNote: string;
};

const RejectDialog = ({ 
  isOpen, 
  handlerReject,
  handlerIsOpenConfirmDialog,
  rejectCounty, 
  dataHandler 
}: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(isOpen);
  const [reasonItems, setReasonItems] = useState<any>([]);

  const fetchCatalogReason = async (catalogCode: string) => {
    const catalog = await agent.Catalog.getCatalogByCode(catalogCode);
    setReasonItems(catalog);
  };
  
  useEffect(() => {
    if (rejectCounty) {
      setValue('reason', 'Live out of County');
      setValue('reasonNote', 'The address is out of county');
    }
  }, [rejectCounty]);

  useEffect(() => {
    if(!dataHandler) return;
    setOpenDialog(isOpen);
  
    const reasonRejectCatalog = 
      dataHandler?.dispositionCatalogCode ? dataHandler?.dispositionCatalogCode : 'DISPOSITION_REASONS';

    fetchCatalogReason(reasonRejectCatalog);
  }, [isOpen, dataHandler]);

  const handleCloseForm = () => {
    reset();
    setOpenDialog(false);
    handlerIsOpenConfirmDialog(false);
  };

  const defaultValues = {
    reason: '',
    reasonNote: '',
  };

  const EventSchema = Yup.object().shape({
    reason: Yup.string().max(255).required('Reason is required'),
    reasonNote: Yup.string().required('Reason Note is required').max(5000),
  });

  const methods = useForm<FormValues>({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const { reset, handleSubmit, setValue } = methods;

  const onSubmit = async (data: FormValues) => {
    try {
      handlerReject({
        ...data,
        ...dataHandler
      });
      handleCloseForm();
    } catch (error) {
      handleCloseForm();
      enqueueSnackbar('An error was occurred', { variant: 'error', autoHideDuration: 6000 });
    }
  };

  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseForm}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="confirm-dialog-title">{dataHandler?.title}</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container>
            <Typography sx={{ mt: 2, ml: 2 }}>{dataHandler?.message}</Typography>
            <Grid item xs={12}>
              <Stack spacing={2} sx={{ p: 2 }}>
                <RHFSelect name="reason" label="Reason*">
                  <MenuItem disabled/>
                  {reasonItems && isArray(reasonItems) && reasonItems?.map((option:any) => (
                    <MenuItem key={option.uuid} value={option.valueDisplayName}>
                      {option?.longDisplayName}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Stack>
              <Stack spacing={2} sx={{ p: 2 }}>
                <RHFTextField name="reasonNote" label="Reason Note*" multiline rows={4} />
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Button onClick={handleCloseForm} variant="contained" color="info">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="info">
                Submit
              </Button>
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
};

export default RejectDialog;
