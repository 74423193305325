import { Box, Card, CircularProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { getFhirIdFromEntity } from 'src/utils/fhir';
import { useBinary } from 'src/@nicheaim/fhir-react';
import { WrappedDocumentReference } from 'src/@nicheaim/fhir-base/wrappers/DocumentReference';
import { getRegistries } from 'src/services/api/registry';
import { generateTemplate } from 'src/services/api/documents';
import useTenantConfigData from 'src/hooks/useTenantConfigData';

const ASSESSMENT_CONTENT_TYPE = 'application/vnd.patientholistics.assess.response.json';

type Props = {
  file?: Blob | null;
  documentReference?: WrappedDocumentReference | null;
  typeResource: 'blob' | 'documentReference';
  patientId?: string;
};

export default function Viewer({ file, documentReference, typeResource, patientId }: Props) {
  const [isViewerReady, setIsViewerReady] = useState(false);
  const { configurations } = useTenantConfigData();
  const [pendingDocument, setPendingDocument] = useState(null);
  const [binaryId, setBinaryData] = useState('');
  const [binary] = useBinary(binaryId);
  const [binaryPHIN, setBinaryPHIN] = useState('');
  const [embeddedBinary, setEmbeddedBinary] = useState('');
  const [isAssessment, setIsAssessment] = useState(false);
  const [isLoadingAssessmentTemplate, setIsLoadingAssessmentTemplate] = useState(false);
  const [assessmentTemplate, setAssessmentTemplate] = useState('');

  useEffect(() => {
    const isAssessmentResponse =
      documentReference?.content?.[0]?.attachment?.contentType === ASSESSMENT_CONTENT_TYPE;
    if (!isAssessmentResponse) return;
    setIsAssessment(isAssessmentResponse);
  }, [documentReference]);

  function isOriginAllowed(originUrl) {
    //TODO validate against env var
    const allowedOrigins = [configurations?.documentViewerUrl];
    return allowedOrigins.includes(originUrl);
  }

  useEffect(() => {
    if (!isAssessment || assessmentTemplate) return;
    const getAssessmentTemplate = async () => {
      setIsLoadingAssessmentTemplate(true);
      try {
        const { data } = await getRegistries(undefined, 'crs-assessment-response-attachment-url');
        const urlData = JSON.parse(data?.[0]?.keyValue) as { url: string; languageURL: string };
        const rawData = atob(binaryPHIN);
        const template = await generateTemplate({
          languageURL: urlData.languageURL,
          templateURL: urlData.url,
          rawData,
        });
        if (!template) return;
        setAssessmentTemplate(template);
      } catch (error) {
        console.log('Error generating template', error);
      }
      setIsLoadingAssessmentTemplate(false);
    };
    getAssessmentTemplate();
  }, [isAssessment, assessmentTemplate]);

  window.addEventListener('message', (e) => {
    //Message received from any window
    if (isOriginAllowed(e.origin) && !isViewerReady) {
      //Message received from viewer and it was not initialized before
      if (e.data && e.data.topic == 'Ready') {
        //Viewer is anouncing it's ready to render documents
        setIsViewerReady(true);
      }
    }
  });

  const convertBlobToBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result?.toString().split(',')[1]);
      };
      reader.readAsDataURL(file);
    });

  const mapFormDataToBinary = async (): Promise<any> => {
    let data: any = null;
    if (typeResource === 'blob' && file) {
      data = await convertBlobToBase64(file);
    }
    return data;
  };

  const data = async () => {
    let contentType: any;
    let dataBase64: any;

    if (typeResource === 'blob') {
      contentType = file?.type;
      dataBase64 = await mapFormDataToBinary();
    } else if (typeResource === 'documentReference') {
      contentType = documentReference?.content?.[0].attachment?.contentType;
      const attachedData = documentReference?.content?.[0]?.attachment?.data;
      if (attachedData) {
        setEmbeddedBinary(attachedData);
        dataBase64 = attachedData;
      } else {
        const id = getFhirIdFromEntity(documentReference?.content?.[0].attachment.url || '');
        setBinaryData(id || '');
        dataBase64 = binary?.data;
      }
    }

    const data = {
      metadata: {
        title: 'Unnamed',
        author: 'N/A',
        custodian: 'N/A',
        date: new Date(),
        contentType,
      },
      dataBase64,
      closeDocument: null,
      emptyDocument: false,
      fhirDocument: null,
    };

    return data;
  };

  const displayDocument = (document: any) => {
    if (isViewerReady) {
      console.log('viewer is ready, sending document.');
      //@ts-ignore
      let win = window.frames.documentPreview;
      win.postMessage({ topic: 'RenderDocument', document: document }, configurations?.documentViewerUrl);
    } else {
      setPendingDocument(document);
      console.log('viewer is not ready document is pending');
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const dataResponse = await data();
      if (isViewerReady && documentReference && ( embeddedBinary)) {
        displayDocument(dataResponse);
      }
    };
    loadData();
  }, [isViewerReady, documentReference, embeddedBinary]);

  useEffect(() => {
    const loadData = async () => {
      const dataResponse = await data();
      if (isViewerReady && file) {
        displayDocument(dataResponse);
      } else if (isViewerReady && documentReference && binary) {
        displayDocument(dataResponse);
      }
    };
    loadData();
  }, [isViewerReady, file, documentReference, binary]);

  return (
    <Card sx={{ m: 1, height: '760px' }}>
      <Stack sx={{ m: 2 }}>
        {!isAssessment ? (
          <iframe
            title="Document Viewer"
            style={{ width: '100%', height: '1000px', border: 'none' }}
            src={configurations?.documentViewerUrl}
            name="documentPreview"
          />
        ) : (
          <>
            {!isLoadingAssessmentTemplate && assessmentTemplate ? (
              <iframe
                title="Assessment Attachment Viewer"
                style={{ width: '100%', height: '1000px', border: 'none' }}
                srcDoc={assessmentTemplate}
                name="documentPreview"
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress size={60} />
              </Box>
            )}
          </>
        )}
      </Stack>
    </Card>
  );
}
