import {
  Autocomplete,
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { isEmpty } from "lodash";
import { useSnackbar } from 'notistack';
import { useState, useMemo, useEffect } from "react";
import { fToNow, getAge } from "src/utils/formatTime";
import { Warning as WarningIcon } from '@mui/icons-material';
import useRequestValidation from "src/hooks/useRequestValidation";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import useAddEntityRequestStates from "src/hooks/useAddEntityRequestStates";
import useLocales from "src/hooks/useLocales";
import { referralService } from "src/crs/referral/services";
import { useActivityDefinitions } from "src/@nicheaim/fhir-react";
import { ActivityDefinitionWrapper } from "src/@nicheaim/fhir-base/wrappers/ActivityDefinition";
import useValueSetsByIdentifiers from "src/hooks/useValueSetsByIdentifier";
import { createTask } from "src/services/api/case";
import { mapNATRequestTask } from "../helpers/request";
  
type Props = {
  open: boolean;
  patient: WrappedPatient | null;
  externalOption?: string;
  handleRefresh?: VoidFunction;
  handlerInitiateHJRequest?: (data: any) => Promise<any>;
  onCancel: VoidFunction;
}

export default function AddRequest({
  open,
  patient,
  externalOption,
  handleRefresh,
  handlerInitiateHJRequest,
  onCancel
}: Props) {
  const { i18n } = useLocales();
  const defaultTheme = useTheme();

  const theme = createTheme({
    palette: {
      ...defaultTheme.palette,
      primary: {
        main: '#b76e00',
        contrastText: 'white',
        lighter: '',
        darker: ''
      },
    },
  });

  const [option, setOption] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [{ error }, { setError }] = useAddEntityRequestStates();
  const [disableRecipient, setDisableRecipient] = useState(false);
  const [natRequestValidation, setNatRequestValidation] = useState<any | null>(null);
  const [recipient, setRecipient] = useState<{ label: string; value: string, contacts: boolean, tasks: boolean } | null>(null);
  
  const {
    isLoading: isLoadingVS,
    valueSets: [taskStatuses, taskIntents, taskPriorities],
  } = useValueSetsByIdentifiers([
    'ph-task-status',
    'ph-task-intent',
    'ph-task-priority',
  ]);

  const [activityDefinition, { isLoading: isLoadingAD } ] = useActivityDefinitions({
    map: ActivityDefinitionWrapper,
    filter: {
      _id: 'Completed-NAT-Assessment',
      status: 'active',
    },
  });

  const optionTitle = option === 'task' ? 
    i18n('nat.request.create.titleTask', 'translations') : 
    i18n('nat.request.create.titleRequest', 'translations');
        
  const {
    ageRangeValidation,
    planDefinition,
    ageRangePatient,
    requestActive,
    relatedPersons,
    isLoading,
    handleRefreshValidation
  } = useRequestValidation(patient?.id ?? '');

  useEffect(() => {
    const allIsLoading = isLoading || isLoadingVS || isLoadingAD;
    setOpenBackdrop(allIsLoading);
    
    if (open && patient && !allIsLoading) {
      setNatRequestValidation({
        ageRangeValidation,
        planDefinition,
        ageRangePatient,
        requestActive,
        relatedPersons,
        isLoading,
        handleRefreshValidation
      });
    }
  }, [open, patient, isLoading, isLoadingVS, isLoadingAD]);

  useEffect(() => {
    if (isLoading || isLoadingVS || isLoadingAD) return;
    if (isEmpty(natRequestValidation)) return;
    if (!isEmpty(externalOption) && natRequestValidation?.planDefinition) {
      setOption('task');
      setShowWarning(false);
    } else if (!isEmpty(externalOption)) {
      setOption('task');
      setShowWarning(true);
    } else {
      setShowWarning(true);
    }
  }, [natRequestValidation, externalOption, isLoading, isLoadingVS, isLoadingAD]);

  const recipientOption = useMemo(
    () => {
      const item = natRequestValidation?.relatedPersons?.
        map((p) => ({
          label: p?.display ?? '',
          value: p?.reference ?? '',
          contact: p?.contact ?? false,
          tasks: p?.tasks?.length > 0 ? true : false
        })) ?? [];
      return [...item];
    },
    [natRequestValidation, patient]
  );

  const handleClose = () => {
    onCancel();
    setOption('');
    setRecipient(null);
    setNatRequestValidation(null);
    setError(null);
    setShowWarning(false)
    setDisableRecipient(false);
  }

  const validateRequiredFields = (payload: any) => {
    let isValid = true;
    if (isEmpty(recipient?.value)) {
      setError('Please, fill required field')
      isValid = false;
    }
    return isValid;
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms || 300));
  }
  
  const handleSubmit = async () => {
    try {

      if (!validateRequiredFields(recipient)) return;

      setOpenBackdrop(true);

      const { request = null, task = null } = mapNATRequestTask({
        patient,
        planDefinition: natRequestValidation?.planDefinition,
        activityDefinition,
        requestActive: natRequestValidation?.requestActive,
        recipient,
        taskPriorities,
        taskIntents,
        taskStatuses
      });

      let response: any;
      if (option === 'request' && request) {
        response = await referralService.createChildReferral(request);
      } else if (option === 'task' && task) {
        if (!natRequestValidation?.requestActive && request && externalOption) {
          response = await referralService.createChildReferral(request);
        }else{
          response = await createTask(task);
        }
      }

      await sleep(12000);
      
      if (handlerInitiateHJRequest) handlerInitiateHJRequest(response);

      if (response) {
        enqueueSnackbar(`${optionTitle} was created.`);
      } else {
        enqueueSnackbar(`An error has ocurred.`, { variant: 'error' });
      }

      if (handleRefresh) handleRefresh();
      handleRefreshValidation();
      setOpenBackdrop(false);

    } catch (error) {
      enqueueSnackbar(`An error has ocurred.`, { variant: 'error' });
    }
    handleClose();
  };

  return (
    <Dialog open={open} maxWidth="md">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle sx={{ py: 2 }}>Create {optionTitle}</DialogTitle>

      <Grid container>
        <Grid item xs={12}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent='center'
            sx={{ background: '#F4F6F8', py: 1, px: 2 }}
            spacing={1}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {i18n('nat.patient.patient', 'translations')}
            </Typography>
            <Typography variant="body2">
              {patient?.getFullName()}
            </Typography>

            {patient?.gender &&
              <>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {i18n('patients.gender', 'crs')}
                </Typography>
                <Typography variant="body2" >
                  {patient?.gender}
                </Typography>
              </>
            }

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {i18n('patients.dateOfBirth', 'crs')}
            </Typography>
            <Typography variant="body2">
              {`${patient?.birthDate && moment.utc(new Date(patient?.birthDate)).format('MMM DD, YYYY')}
              ${patient?.birthDate && ` (${getAge(new Date(patient?.birthDate))} years)`}`}
            </Typography>

            {natRequestValidation?.planDefinition &&
              <>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>

                </Typography>
                <Typography variant="body2">
                  {natRequestValidation?.ageRangePatient?.description || ''}
                </Typography>
              </>
            }

            {natRequestValidation?.requestActive?.authoredOn &&
              <>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {i18n('nat.request.create.lastHJInvitation', 'translations')}
                </Typography>
                <Typography variant="body2">
                  {natRequestValidation?.requestActive?.authoredOn &&
                    fToNow(natRequestValidation?.requestActive?.authoredOn || '')}
                </Typography>
              </>
            }
          </Stack>
        </Grid>
      </Grid>

      {showWarning &&
        <Grid container sx={{ py: 2, px: 3, background: '#fff5cc' }}>
          <Grid item xs={!natRequestValidation?.planDefinition ? 12 : 8}>
            <Stack
              spacing={2}
              direction={{ xs: 'column', md: 'row' }}
              alignItems='center'
              justifyContent='flex-start'
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <IconButton>
                  <WarningIcon htmlColor="#ffab00" />
                </IconButton>
              </Typography>

              {!natRequestValidation?.planDefinition ? (
                <Typography 
                  variant="body2" 
                  sx={{ color: 'text.secondary' }}
                  dangerouslySetInnerHTML={{
                    __html: `${i18n('nat.request.create.noPlanDefinition', 'translations')}`
                  }}
                />
              ) : natRequestValidation?.requestActive && !natRequestValidation?.ageRangeValidation ? (
                <Typography 
                  variant="body2" 
                  sx={{ color: 'text.secondary' }}
                  dangerouslySetInnerHTML={{
                    __html: `${i18n('nat.request.create.requestActiveAndNoAgeRangeValidation', 'translations')}`
                  }}
                />
              ) : natRequestValidation?.ageRangeValidation && natRequestValidation?.requestActive ? (
                <Typography 
                  variant="body2" 
                  sx={{ color: 'text.secondary' }}
                  dangerouslySetInnerHTML={{
                    __html: `${i18n('nat.request.create.ageRangeValidationAndRequestActive', 'translations')}`
                  }}
                />
              ) : natRequestValidation?.planDefinition && !natRequestValidation?.requestActive ? (
                <Typography 
                  variant="body2" 
                  sx={{ color: 'text.secondary' }}
                  dangerouslySetInnerHTML={{
                    __html: `${i18n('nat.request.create.planDefinitionAndNoRequestActive', 'translations')}`
                  }}
                />
              ) : ''}
            </Stack>
          </Grid>

          {natRequestValidation?.planDefinition &&
            <Grid item xs={4}>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                alignItems='center'
                justifyContent='center'
                spacing={2}
              >
                <ThemeProvider theme={theme}>
                  {(natRequestValidation?.requestActive !== null && 
                    natRequestValidation?.ageRangeValidation) &&
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        <Button
                          variant="outlined"
                          sx={{ borderRadius: 2, textTransform: 'capitalize' }}
                          onClick={() => {
                            setOption('task')
                            setShowWarning(false)
                          }}
                        >
                          <strong>Create {i18n('nat.request.create.titleTask', 'translations')}</strong>
                        </Button>
                      </Typography>
                  }

                  <Typography variant="body2">
                    <Button
                      variant="contained"
                      sx={{ borderRadius: 2, textTransform: 'capitalize' }}
                      disabled={natRequestValidation?.planDefinition ? false : true}
                      onClick={() => {
                        setOption('request')
                        setShowWarning(false)
                      }}
                    >
                      Create {i18n('nat.request.create.titleRequest', 'translations')}
                    </Button>
                  </Typography>

                </ThemeProvider>
              </Stack>
            </Grid>
          }

        </Grid>
      }

      {(recipient && recipient?.tasks && option === 'task') &&
        <Grid container sx={{ py: 2, px: 3, background: '#fff5cc' }}>
          <Grid item xs={8}>
            <Stack
              spacing={2}
              direction={{ xs: 'column', md: 'row' }}
              alignItems='center'
              justifyContent='flex-start'
            >
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <IconButton>
                  <WarningIcon htmlColor="#ffab00" />
                </IconButton>
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {i18n('nat.request.create.recipientTask', 'translations')}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      }

      <Stack
        spacing={2}
        direction='row'
        sx={{ mx: 4, my: 2 }}
      >
        <FormControl fullWidth>
          <Typography variant="body2" sx={{ pb: 1, fontWeight: 'bold' }}>
            Choose Available Recipient
          </Typography>

          <Autocomplete
            freeSolo
            disabled={isEmpty(option) ? true : false || disableRecipient}
            value={recipient}
            onChange={(event, newValue) => {
              if (!newValue?.contact && option === 'task') {
                setDisableRecipient(true);
                setError('This recipient does not have an email address or phone number, please enter at least one.')
              } else {
                setRecipient(newValue);
                setError(null);
              }
            }}
            options={recipientOption || []}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option?.value}
                  size="small"
                  label={option?.label}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                label="" {...params}
                error={!!error}
                helperText={!!error && error}
              />
            )}
          />
        </FormControl>
      </Stack>

      <Stack alignItems="flex-end">
        <DialogActions>
          <Button variant="text" color="inherit" onClick={handleClose}>
            {i18n('cancel')}
          </Button>
          {natRequestValidation?.ageRangePatient?.code && natRequestValidation?.planDefinition &&
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isEmpty(option) ? true : false || disableRecipient}
              onClick={handleSubmit}
            >
              Create {optionTitle}
            </Button>
          }
        </DialogActions>
      </Stack >
    </Dialog >
  )
}