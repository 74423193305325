export type TableHead = {
  id: string;
  label: string;
  align: string;
};

export const TABLE_HEAD_NOTES = [
  { id: 'topic', label: 'patients.details.notes.topic', align: 'center' },
  { id: 'author', label: 'patients.details.notes.author', align: 'left' },
  { id: 'type', label: 'patients.details.notes.type', align: 'left' },
  { id: 'description', label: 'patients.details.notes.description', align: 'left' },
  { id: 'date', label: 'patients.details.notes.date', align: 'center' },
  { id: 'medium', label: 'patients.details.notes.medium', align: 'center' },
];

export const TABLE_HEAD_OBSERVATION = [
  { id: 'code', label: 'patients.details.observations.code', align: 'left' },
  { id: 'issued', label: 'patients.details.observations.issued', align: 'left' },
  { id: 'value', label: 'patients.details.observations.value', align: 'left' },
  { id: 'category', label: 'patients.details.observations.category', align: 'left' },
  { id: 'status', label: 'patients.details.observations.status', align: 'left' },
  { id: 'actions', label: 'patients.details.observations.actions', align: 'center' },
];

export const TABLE_HEAD_ASSESSMENTS = [
  { id: 'surveyId', label: 'patients.details.assessments.surveyId', align: 'center' },
  { id: 'responseId', label: 'patients.details.assessments.responseId', align: 'left' },
  { id: 'language', label: 'patients.details.assessments.language', align: 'left' },
  { id: 'type', label: 'patients.details.assessments.type', align: 'center' },
  { id: 'submitDate', label: 'patients.details.assessments.submitDate', align: 'center' },
  { id: 'previewassessments', label: 'patients.details.assessments.preview', align: 'center' },
];

export const TABLE_HEAD_DOCUMENTS_PATIENT = [
  { id: 'preview', label: 'patients.details.attachments.preview', align: 'left' },
  { id: 'name', label: 'patients.details.attachments.name', align: 'left' },
  { id: 'author', label: 'patients.details.attachments.author', align: 'left' },
  { id: 'custodian', label: 'patients.details.attachments.custodian', align: 'left' },
  { id: 'type', label: 'patients.details.attachments.type', align: 'left' },
  { id: 'category', label: 'patients.details.attachments.category', align: 'left' },
  { id: 'date', label: 'patients.details.attachments.date', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'center' },
];

export const TABLE_HEAD_ATTACH = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'author', label: 'Author', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'mimeType', label: 'Mime Type', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'preview', label: 'Preview', align: 'center' },
];

export const STATUS_OPTION_IMMUNIZATION = [
  { label: 'In Progress', value: 'not-done' },
  { label: 'Completed', value: 'completed' },
  { label: 'Canceled', value: 'entered-in-error' },
  { label: 'In Progress', value: 'in-progress' },
];

export const TABLE_HEAD_IMMUNIZATIONS = [
  { id: 'name', label: 'patients.details.immunizations.name', align: 'left' },
  { id: 'doses', label: 'patients.details.immunizations.doses', align: 'left' },
  { id: 'status', label: 'patients.details.immunizations.status', align: 'center' },
  { id: 'occurrence', label: 'patients.details.immunizations.occurrence', align: 'left' },
  { id: 'lotNumber', label: 'patients.details.immunizations.lotNumber', align: 'left' },
  { id: 'vaccineCode', label: 'patients.details.immunizations.vaccineCode', align: 'left' },
];

export const TABLE_HEAD_CASES = [
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'assignedTo', label: 'Assigned To', align: 'left' },
  { id: 'scope', label: 'Scope', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
];

export const TABLE_HEAD_CONTACT = [
  { id: 'when', label: 'referral.details.contactAttempts.when', align: 'center' },
  { id: 'outcome', label: 'referral.details.contactAttempts.outcome', align: 'left' },
  { id: 'type', label: 'referral.details.contactAttempts.type', align: 'left' },
  { id: 'nextContact', label: 'referral.details.contactAttempts.nextContact', align: 'left' },
  { id: 'notes', label: 'referral.details.notes', align: 'center' },
];

export const TABLE_HEAD_RELATED_PERSON = [
  { id: 'name', label: 'patients.details.contacts.name', align: 'left' },
  { id: 'contact', label: 'patients.details.contacts.contact', align: 'left' },
  { id: 'actions', label: 'patients.details.contacts.actions', align: 'center' },
];

export const TABLE_HEAD_CONSENT_FORM = [
  { id: 'medium', label: 'referral.details.consentForm.medium', align: 'center' },
  { id: 'author', label: 'referral.details.consentForm.author', align: 'left' },
  { id: 'date', label: 'referral.details.date', align: 'left' },
  { id: 'status', label: 'referral.details.status', align: 'left' },
];

export const TABLE_HEAD_DISPOSITION_REFERRAL_CASE = [
  { id: 'caseNumber', label: 'Case Number', align: 'left' },
  { id: 'created', label: 'Created', align: 'left' },
  { id: 'assignedTo', label: 'Assigned To', align: 'left' },
  { id: '', label: '', align: 'center' },
];

export const TABLE_HEAD_CHILD_REFERRAL = [
  { id: 'status', label: 'referral.details.status', align: 'left' },
  { id: 'assign', label: 'referral.details.assignedTo', align: 'left' },
  { id: 'created', label: 'referral.details.secondaryReferrals.createdOn', align: 'left' },
  { id: 'service', label: 'referral.details.secondaryReferrals.service', align: 'left' },
  { id: 'referred', label: 'referral.details.secondaryReferrals.referredFrom', align: 'left' },
  { id: 'performer', label: 'referral.details.secondaryReferrals.referredTo', align: 'left' },
  { id: 'location', label: 'referral.details.secondaryReferrals.location', align: 'left' },
  { id: '', label: '', align: 'center' },
];

export const TABLE_HEAD_CHILD_REFERRAL_SEARCH_SERVICE = [
  { id: 'service', label: 'Service', align: 'left' },
  { id: 'organization', label: 'Organization', align: 'left' },
  { id: 'location', label: 'Location', align: 'left' },
  { id: '', label: '', align: 'center' },
];

export const TABLE_HEAD_PATIENT_LIST = [
  { id: 'id', customSort: true, label: 'patients.mrn', align: 'left' },
  { id: 'patient', customSort: true, label: 'patients.list.columns.patient', align: 'left' },
  { id: 'contact', customSort: true, label: 'patients.list.columns.contact', align: 'left' },
  { id: 'indexedStatus', customSort: false, label: 'patients.list.columns.status', align: 'left' },
  { id: 'indexedEnrollment', customSort: false, sortable: false, label: 'patients.list.columns.enrollment', align: 'left' },
  { id: 'indexedCustomLastSil', customSort: false, label: 'patients.list.columns.info', align: 'left' },
  { id: 'dates', customSort: true, label: 'patients.list.columns.dates', align: 'left' },
];

export const TABLE_HEAD_REFERRAL_LIST = [
  { id: 'internalNumber', label: 'referral.list.columns.referralNo', align: 'left' },
  { id: 'referral', label: 'referral.list.columns.patient', align: 'left' },
  { id: 'demographics', label: 'referral.list.columns.demographics', align: 'left' },
  { id: 'referringProvider', label: 'referral.list.columns.referringProvider', align: 'left' },
  {
    id: 'requesterOrganization',
    label: 'referral.list.columns.requesterOrganization',
    align: 'left',
  },
  { id: 'registrationDate', label: 'referral.list.columns.registrationDate', align: 'left' },
  { id: 'step', label: 'referral.list.columns.step', align: 'left' },
  { id: 'status', label: 'referral.list.columns.status', align: 'left' },
  { id: '', label: '' },
];

export const TABLE_HEAD_CASES_LIST = [
  { id: 'internalNumber', label: 'case.list.columns.caseNo', align: 'left' },
  { id: 'patient', label: 'case.list.columns.patient', align: 'left' },
  { id: 'step', label: 'case.list.columns.step', align: 'left' },
  { id: 'date', label: 'case.list.columns.registrationDate', align: 'left' },
  { id: 'status', label: 'case.list.columns.status', align: 'left' },
];

export const TABLE_HEAD_REFERRAL_PATIENT = [
  { id: 'number', label: 'Number', align: 'left' },
  { id: 'created', label: 'Created', align: 'left' },
  { id: 'birthDate', label: 'Age', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'workflowStatus', label: 'Workflow Status', align: 'left' },
  { id: 'owner', label: 'Owner', align: 'left' },
  { id: 'scope', label: 'Scope', align: 'left' },
  { id: 'step', label: 'Step', align: 'left' },
  { id: 'menu', label: '', align: 'left' },
];

export const TABLE_HEAD_SERVICE_REQUEST_PATIENT = [
  { id: 'internalNumber', label: 'patients.details.serviceRequests.internalNumber', align: 'left' },
  { id: 'created', label: 'patients.details.serviceRequests.created', align: 'left' },
  { id: 'referredFrom', label: 'patients.details.serviceRequests.referredFrom', align: 'left' },
  { id: 'service', label: 'patients.details.serviceRequests.service', align: 'left' },
  { id: 'providedBy', label: 'patients.details.serviceRequests.providedBy', align: 'left' },
  { id: 'status', label: 'patients.details.serviceRequests.status', align: 'left' },
  { id: 'action', label: 'patients.details.serviceRequests.action', align: 'left' },
];

export const TABLE_HEAD_CASE_PATIENT = [
  { id: 'number', label: 'patients.details.cases.number', align: 'left' },
  { id: 'created', label: 'patients.details.cases.created', align: 'left' },
  { id: 'birthDate', label: 'patients.details.cases.birthDate', align: 'left' },
  { id: 'status', label: 'patients.details.cases.status', align: 'left' },
  { id: 'workflowStatus', label: 'patients.details.cases.workflowStatus', align: 'left' },
  { id: 'owner', label: 'patients.details.cases.owner', align: 'left' },
  { id: 'scope', label: 'patients.details.cases.scope', align: 'left' },
  { id: 'step', label: 'patients.details.cases.step', align: 'left' },
  { id: 'menu', label: '', align: 'left' },
];

export const TABLE_HEAD_CARE_PLAN_PATIENT = [
  { id: 'created', label: 'patients.details.carePlans.created', align: 'left' },
  { id: 'status', label: 'patients.details.carePlans.status', align: 'left' },
  { id: 'intent', label: 'patients.details.carePlans.intent', align: 'left' },
  { id: 'category', label: 'patients.details.carePlans.category', align: 'left' },
  { id: 'activities', label: 'patients.details.carePlans.activities', width: '3%', align: 'left' },
  { id: 'actions', label: '', align: 'left' },
];

export const TABLE_HEAD_APPOINTMENT = [
  { id: 'status', label: 'patients.details.appointments.status', align: 'left' },
  { id: 'description', label: 'patients.details.appointments.description', align: 'left' },
  { id: 'start', label: 'patients.details.appointments.start', align: 'left' },
  { id: 'end', label: 'patients.details.appointments.end', align: 'left' },
  { id: 'participants', label: 'patients.details.appointments.participants', align: 'left' },
  { id: 'created', label: 'patients.details.appointments.created', align: 'left' },
  { id: '', label: '', align: 'left' },
];

export const TABLE_HEAD_APPOINTMENT_PARTICIPANT = [
  { id: 'participant', label: 'patients.details.appointments.participant', align: 'left' },
  { id: 'required', label: 'patients.details.appointments.required', align: 'left' },
  { id: 'status', label: 'patients.details.appointments.statusParticipant', align: 'left' },
  { id: '', label: '', align: 'left' },
];

export const TABLE_HEAD_CONDITION = [
  { id: 'code', label: 'patients.details.conditions.code', align: 'left', width: '30%' },
  {
    id: 'description',
    label: 'patients.details.conditions.description',
    align: 'left',
    width: '45%',
  },
  { id: 'priority', label: 'patients.details.conditions.priority', align: 'left', width: '5%' },
  { id: 'start', label: 'patients.details.conditions.start', align: 'left', width: '5%' },
  { id: 'end', label: 'patients.details.conditions.end', align: 'left', width: '5%' },
  { id: 'created', label: 'patients.details.conditions.created', align: 'left', width: '20%' },
  { id: '', label: '', align: 'left', width: '50%' }
];

export const TABLE_HEAD_EPISODE_OF_CARE = [
  { id: 'status', label: 'patients.details.episodeOfCare.status', align: 'left', width: '30%' },
  {
    id: 'type',
    label: 'patients.details.episodeOfCare.type',
    align: 'left',
    width: '45%',
  },
  { id: 'start', label: 'patients.details.episodeOfCare.start', align: 'left', width: '5%' },
  { id: 'end', label: 'patients.details.episodeOfCare.end', align: 'left', width: '5%' },
  {
    id: 'careManager',
    label: 'patients.details.episodeOfCare.careManager',
    align: 'left',
    width: '45%',
  },
  { id: '', label: '', align: 'left', width: '50%' },
  { id: '', label: '', align: 'left', width: '50%' },
];

export const TABLE_HEAD_DEVICE = [
  { id: 'deviceName', label: 'patients.details.device.name', align: 'left', width: '30%' },
  { id: 'status', label: 'patients.details.device.status', align: 'left', width: '30%' },
  // {
  //   id: 'description',
  //   label: 'patients.details.conditions.description',
  //   align: 'left',
  //   width: '45%',
  // },
  // { id: 'start', label: 'patients.details.conditions.start', align: 'left', width: '5%' },
  // { id: 'end', label: 'patients.details.conditions.end', align: 'left', width: '5%' },
  // { id: 'created', label: 'patients.details.conditions.created', align: 'left', width: '20%' },
  // { id: '', label: '', align: 'left', width: '50%' },
  // { id: '', label: '', align: 'left', width: '50%' },
];

export const TABLE_HEAD_THEME_SETTINGS = [
  { id: 'keyRegistry', label: 'Registry', align: 'left' },
  { id: 'groupIdentifier', label: 'Group Identifier', align: 'left' },
  { id: 'keyDescription', label: 'Description', align: 'left' },
  { id: 'createdOn', label: 'Created', align: 'left' },
  { id: '', label: '', align: 'center' },
];

export const TABLE_HEAD_INCIDENT_LIST = [
  { id: 'created', label: 'Created', align: 'left' },
  { id: 'summary', label: 'Summary', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'assignedTo', label: 'Assigned To', align: 'left' },
  { id: 'scope', label: 'Scope', align: 'left' },
  { id: 'disposition', label: 'Disposition', align: 'left' },
];
