import { useState, useRef, MouseEvent } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { SvgIconComponent, MoreVert as MoreVertIcon } from '@mui/icons-material';

export interface MenuOption {
  IconComponent: SvgIconComponent;
  title: string;
  callback: Function;
  iconColor?: React.CSSProperties['color'];
  titleColor?: React.CSSProperties['color'];
}

interface ActionButtonProps {
  menuOptions?: MenuOption[];
  renderChildren?: (isMenuOpen: boolean, setIsMenuOpen: Function) => React.ReactNode;
  customButton?:
    | ((
        isMenuOpen: boolean,
        setIsMenuOpen: Function,
        onClick: (event?: MouseEvent<HTMLButtonElement>) => void,
        ref: any
      ) => React.ReactNode)
    | null;
  useCustomButton?: boolean;
}

const ActionButton = ({
  menuOptions,
  renderChildren,
  customButton = null,
  useCustomButton = false,
}: ActionButtonProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const menuElementRef = useRef(null);
  const toggleMenu = (event?: MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation?.();
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  return (
    <>
      {useCustomButton ? (
        customButton?.(isMenuOpen, setIsMenuOpen, toggleMenu, menuElementRef)
      ) : (
        <IconButton onClick={toggleMenu} ref={menuElementRef}>
          <MoreVertIcon htmlColor="#241f20" />
        </IconButton>
      )}

      <Menu
        id="lock-menu"
        anchorEl={menuElementRef.current}
        open={isMenuOpen}
        onClose={() => {
          toggleMenu();
        }}
      >
        {menuOptions?.length
          ? menuOptions.map(({ title, callback, IconComponent, iconColor, titleColor }, index) => (
              <MenuItem
                key={title + String(index)}
                onClick={() => {
                  callback();
                  toggleMenu();
                }}
              >
                <IconComponent
                  sx={{ marginRight: '10px' }}
                  htmlColor={iconColor ? iconColor : '#241f20'}
                />
                <Typography variant={'body2'} sx={{ color: titleColor ? titleColor : 'inherit' }}>
                  {title}
                </Typography>
              </MenuItem>
            ))
          : renderChildren?.(isMenuOpen, setIsMenuOpen)}
      </Menu>
    </>
  );
};

export default ActionButton;
