import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// @types
import { OrganizationManager } from 'src/@types/organization';
// components
import { FormProvider, RHFTextField } from '../../components/hook-form';
import api from 'src/services/api';
import useValueSetsByIdentifiers from 'src/hooks/useValueSetsByIdentifier';
import { IdentifierDetails } from '../crs/patient/components/details/IdentifierDetails';
import { useOrganization } from 'src/@nicheaim/fhir-react';
import { OrganizationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import produce from 'immer';
import { Identifier } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import useLocales from 'src/hooks/useLocales';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

// ----------------------------------------------------------------------

type FormValuesProps = OrganizationManager;

type Props = {
  isEdit: boolean;
  currentOrganization?: OrganizationManager;
};

export default function OrganizationNewEditForm({ isEdit, currentOrganization }: Props) {
  const navigate = useNavigate();
  const { i18n } = useLocales();

  const {
    valueSets: [organizationIdentifierTypes],
  } = useValueSetsByIdentifiers(['ph-organization-identifier-types']);

  const [organization, { update: updateOrganization }] = useOrganization(
    currentOrganization?.fhirId,
    {
      map: OrganizationWrapper,
      autofetch: !!currentOrganization?.fhirId,
    }
  );

  const onUpdateIdentifiers = useCallback(
    async (newIdentifiers: Identifier[]) => {
      await updateOrganization(
        produce(organization!, (draft) => {
          draft.identifier = newIdentifiers;
        })
      );
    },
    [organization]
  );

  const { enqueueSnackbar } = useSnackbar();

  const NewOrganizationSchema = Yup.object().shape({
    name: Yup.string().required('Organization name is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentOrganization?.name || '',
    }),
    [currentOrganization]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewOrganizationSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (isEdit && currentOrganization) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
  }, [isEdit, currentOrganization]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      if (!isEdit) await api.organizations.createOrganization({
        ...data,
        active: true
      });
      else await api.organizations.updateOrganization(data, currentOrganization?.uuid);
      reset();
      enqueueSnackbar(!isEdit ? 'Create success!' : 'Update success!');
      navigate(PATH_DASHBOARD.organization.list);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Oops', { variant: 'error' });
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <RHFTextField name="name" label={`${i18n('admin.name')}*`} />
              </Box>
              <Grid container justifyContent={'flex-end'}>
                <Grid item xs={2}>
                  <Stack justifyContent="flex-end" sx={{ mt: 3 }}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      sx={{ width: 'px' }}
                    >
                      {!isEdit
                        ? `${i18n('admin.newOrganization.createOrganization')}`
                        : `${i18n('admin.newOrganization.saveChanges')}`}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Card>
            {checkAclValidation({
              acls: [crsAcls.ADMIN.ORGANIZATIONS.IDENTIFIER_DETAILS.VIEW],
            }) && (
              <>
                {!!currentOrganization?.fhirId &&
                  !!organizationIdentifierTypes?.asList &&
                  !!organization?.id && (
                    <Grid xs={6} mt={2}>
                      <Card sx={{ p: 3 }}>
                        <IdentifierDetails
                          identifiers={organization?.identifier ?? []}
                          catalog={organizationIdentifierTypes?.asList?.() ?? []}
                          systemVS={organizationIdentifierTypes?.getConceptSystem()}
                          onUpdateIdentifiers={onUpdateIdentifiers}
                          permissions={{
                            isAllowedToAdd: checkAclValidation({
                              acls: [crsAcls.ADMIN.ORGANIZATIONS.IDENTIFIER_DETAILS.ADD],
                            }),
                            isAllowedToEdit: checkAclValidation({
                              acls: [crsAcls.ADMIN.ORGANIZATIONS.IDENTIFIER_DETAILS.EDIT],
                            }),
                            isAllowedToDelete: checkAclValidation({
                              acls: [crsAcls.ADMIN.ORGANIZATIONS.IDENTIFIER_DETAILS.DELETE],
                            }),
                            isAllowedToViewHidden: checkAclValidation({
                              acls: [crsAcls.ADMIN.ORGANIZATIONS.IDENTIFIER_DETAILS.HIDDEN.VIEW],
                            }),
                            isAllowedToEditHidden: checkAclValidation({
                              acls: [crsAcls.ADMIN.ORGANIZATIONS.IDENTIFIER_DETAILS.HIDDEN.EDIT],
                            }),
                            isAllowedToDeleteHidden: checkAclValidation({
                              acls: [crsAcls.ADMIN.ORGANIZATIONS.IDENTIFIER_DETAILS.HIDDEN.DELETE],
                            })
                          }}
                        />
                      </Card>
                    </Grid>
                  )}
              </>
            )}
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
}
