import { 
  Button, 
  Card, 
  Checkbox, 
  Chip, 
  Link, 
  Stack, 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow 
} from "@mui/material";
import { useState } from "react";
import { upperCase } from "lodash";
import Scrollbar from "src/components/Scrollbar";
import Viewer from "src/sections/crs/common/Viewer";
import { Controller, useFormContext } from "react-hook-form";
import { WrappedServiceRequest } from "src/@nicheaim/fhir-base/wrappers/ServiceRequest";
import { WrappedDocumentReference } from "src/@nicheaim/fhir-base/wrappers/DocumentReference";

const styleTableLastCell = [
  {
    '&.MuiTableCell-root': { 
      textAlign: 'center',  p: 0, 
      '&:last-of-type': {
        paddingRight: 1,
      }
    }
  }
];

type TableLinkProps = {
  documents: WrappedDocumentReference[] | null;
  serviceRequest: WrappedServiceRequest | null;
  programs: any;
  patientId?: string;
};
  
export default function TableLink ({
  documents, 
  serviceRequest, 
  programs, 
  patientId
} : TableLinkProps) {
  
  const [ data, setData ] = useState(null);

  const [ showViewer, setShowViewer ] = useState(false);

  const methods = useFormContext();

  const {
    control, 
    setValue,
  } = methods;

  const linkDocuments = documents?.
    filter(r => r.context?.related?.find(e => e.reference === `ServiceRequest/${serviceRequest?.id}`)); 

  const handleClick = (documentSelected:any, )=>{
    setShowViewer(true);
    setData(documentSelected);
  }
  
  return(
    <>
      <Card sx={{ m: 1 }}>
        <Scrollbar sx={{ height: 400, width: '100%', mb: 2 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundImage: 'inherit' }}></TableCell>
                  {programs?.map((col: any, index: number) => (
                    <TableCell key={`col-${index}`} sx={{ backgroundImage: 'inherit', textAlign: 'center' }}>
                      <Chip 
                        size="small" 
                        key={col?.code?.code} 
                        label={`${upperCase(col?.code?.code)}`} 
                        sx={{ backgroundColor: 'green', color: 'white' }} 
                      />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody> 
                {linkDocuments?.map((row: any, index1: number) => (
                  <TableRow key={index1} >
                    <TableCell sx={{ p: 1, backgroundImage: 'inherit', }}> 
                      <Link 
                        variant="subtitle2" 
                        color="#1890FF" 
                        underline="always" 
                        onClick={() => handleClick(row)}
                      >
                        {row.content[0]?.attachment?.title}  
                      </Link>
                    </TableCell>
                    {programs.map((v:any, index:number) => (
                      
                      <TableCell key={v?.code} sx={ styleTableLastCell }>
                       <Controller
                          key={index}
                          name={`documentPrograms.${index1}.programChecked.${index}`}
                          control={control}
                          render={({ field }) => (
                            <Checkbox 
                              checked={field.value}
                              value={field.value}
                              onChange={(newValue) => {
                                field.onChange(newValue)
                                setValue(`documentPrograms.${index1}.programChecked.${index}.programId`,v.programId);
                                setValue(`documentPrograms.${index1}.programChecked.${index}.checked`,newValue.target.checked);
                                setValue(`documentPrograms.${index1}.documentReference`,row);
                              }}
                            />
                          )}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
        </Scrollbar>
        <Stack alignItems="center" sx={{ p: 1 }}>
          <Button variant="outlined" color="info" type="submit">
            Save
          </Button>
        </Stack>
      </Card>
      {showViewer && ( <Viewer documentReference={data} typeResource="documentReference" patientId={patientId} />)}
    </>
  )
}