const baseUrl = `${process.env.REACT_APP_HOST_API}/core`; 
const fhirBaseUrl = process.env.REACT_APP_FHIR_API_BASE_URL;

const apiRoutes = {
  getWorkflowsList: `${baseUrl}/workflow`,
  getWorkflowDetails: `${baseUrl}/workflow/{code}`,
  getWorkflowBoxesPatients: `${baseUrl}/workflow/box/patientengagement`,
  getWorkflowBoxDetails: `${baseUrl}/workflow/box/{uuid}`,
  postEngagementWorkflowBox: `${baseUrl}/workflow/ENGAGEMENT`,
  putWorkflowBox: `${baseUrl}/workflow/box`,
  getWorkflowStageDetails: `${baseUrl}/workflow/box/{uuid}/stage/{code}`,
  getWorkflowStepDetails: `${baseUrl}/workflow/box/{uuid}/stage/{stageCode}/step/{stepCode}`,
  getWorkflowsListBoxes: `${baseUrl}/box`,
  getFhirPatients: `${fhirBaseUrl}/Patient`,
  putFhirPatient:`${fhirBaseUrl}/Patient`,
  getFhirPatientById: `${fhirBaseUrl}/Patient?_id=`,
  postFhirBinary: `${fhirBaseUrl}/Binary`,
  postFhirDocumentReference: `${fhirBaseUrl}/DocumentReference`,
  getFhirDocumentReference: `${fhirBaseUrl}/DocumentReference?patient=`,
  postFhirConsent: `${fhirBaseUrl}/Consent`,
  getFhirConsentByPatient: `${fhirBaseUrl}/Consent?patient=`,
  postFhirRelatedPerson: `${fhirBaseUrl}/RelatedPerson`,
  getFhirRelatedPersonbyPatient: `${fhirBaseUrl}/RelatedPerson?patient=`,
  getPractitionerRole: `${fhirBaseUrl}/PractitionerRole`,
  getWorkFlowBoxes: `${baseUrl}/workflow/box/`,
  getScreeningResult: `${fhirBaseUrl}/Task?patient`,
  getPractitionerRoles: `${fhirBaseUrl}/PractitionerRole?`,
  getPractitionerRoleByIds: `${fhirBaseUrl}/PractitionerRole?_id=`,
  getCodes: `${fhirBaseUrl}/ValueSet/ph-reason-referral`,
  getLanguages: `${fhirBaseUrl}/ValueSet/simple-language`,
  getFhirOrganizations:`${fhirBaseUrl}/Organization`,
  postFhirServiceRequest:`${fhirBaseUrl}/ServiceRequest`,
  postTaskAssessment:`${process.env.REACT_APP_HOST_API}/task/assessment`,
  getDispositionCode:`${baseUrl}/workflow/catalog/?code=DISPOSITION_REASONS`,
  postWorkflowReason:`${baseUrl}/workflow/box`,
  getEnrolments:`${fhirBaseUrl}/EpisodeOfCare`,
  getFhirTask: `${fhirBaseUrl}/Task`,
  getContacts:`${baseUrl}/workflow/box/`,
  postEngagementPatient: `${baseUrl}/workflow/box/ENGAGEMENT`,
  postScopeData:`${baseUrl}/workflow/box`,
  // getDispositionReasons:`${baseUrl}/workflow/catalog/?code=INTAKE_ENGAGEMENT`
};

export { apiRoutes};
