import { capitalCase } from 'change-case';
import { Box, Tab, Tabs } from '@mui/material';
import useTabs from '../../../../hooks/useTabs';
import ContactDetails from './details/ContactDetails';
import PatientGroups from './PatientGroups';
import PatientInsurance from './PatientInsurance';
import PatientReferralCases from './PatientReferralCases';
import PersonalDetails from './details/PersonalDetails';
import { PatientDetails } from '../PatientDetails';
import { Section, TwoColumnLayout } from 'src/utils/layout';
import { IdentifierDetails } from './details/IdentifierDetails';
import { NewAddressDetails } from './details/NewAddressDetails';
import PatientDocuments from './PatientDocuments';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PatientHealth from './PatientHealth';
import { usePatient, useValueSets } from 'src/@nicheaim/fhir-react';
import { ValueSetWrapper } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { PatientWrapper } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { useCallback, useMemo } from 'react';
import produce from 'immer';
import { Identifier } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import PatientTasks from './PatientTasks';
import ExpandableCard from 'src/components/common/ExpandableCard';
import useLocales from 'src/hooks/useLocales';
import EngagementHub from 'src/sections/engagement/common/EngagementHub';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import PatientConsents from './PatientConsents';

export default function PatientTabs({ patient, onRefresh }: PatientDetails) {
  const history = useLocation();
  const { i18n } = useLocales();

  const pathuser =
    history.pathname?.slice(history.pathname?.lastIndexOf('/') + 1, history.pathname?.length)
      .length > 20 ||
    history.pathname?.slice(history.pathname?.lastIndexOf('/') + 1, history.pathname?.length) ==
      'general'
      ? 'general'
      : history.pathname?.slice(history.pathname?.lastIndexOf('/') + 1, history.pathname?.length);

  const PATIENT_TABS = [
    {
      value: `${i18n('patients.details.tabs.general', 'crs')}`,
      component: <PatientDetailsView patient={patient} onRefresh={onRefresh} />,
      path: 'general',
    },
    ...(checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TABS.REFERRAL_CASES] })
      ? [
          {
            value: `${i18n('patients.details.tabs.referal', 'crs')}`,
            component: <PatientReferralCases patient={patient} />,
            path: 'referrals-cases',
          },
        ]
      : []),
    ...(checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TABS.HEALTH] })
      ? [
          {
            value: `${i18n('patients.details.tabs.health', 'crs')}`,
            component: <PatientHealth patient={patient} />,
            path: 'health',
          },
        ]
      : []),
    ...(checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TABS.GROUPS] })
      ? [
          {
            value: `${i18n('patients.details.tabs.groups', 'crs')}`,
            component: <PatientGroups patient={patient} onRefresh={onRefresh} />,
            path: 'groups',
          },
        ]
      : []),
    ...(checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TABS.INSURANCE] })
      ? [
          {
            value: `${i18n('patients.details.tabs.insurance', 'crs')}`,
            component: <PatientInsurance patient={patient} />,
            path: 'insurance',
          },
        ]
      : []),
    ...(checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TABS.DOCUMENTS] })
      ? [
          {
            value: `${i18n('patients.details.tabs.documents', 'crs')}`,
            component: <PatientDocuments patient={patient} />,
            path: 'documents',
          },
        ]
      : []),
    ...(checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TABS.TASKS] })
      ? [
          {
            value: `${i18n('patients.details.tabs.tasks', 'crs')}`,
            component: <PatientTasks patient={patient} />,
            path: 'tasks',
          },
        ]
      : []),
    ...(checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TABS.ENGAGEMENT_HUB] })
      ? [
          {
            value: 'engagement_hub',
            component: <EngagementHub patient={patient} />,
            path: 'engagement-hub',
          },
        ]
      : []),
    ...(checkAclValidation({ acls: [crsAcls.CRS.PATIENT.TABS.CONSENTS] })
      ? [
          {
            value: `${i18n('patients.details.tabs.consents', 'crs')}`,
            component: <PatientConsents patient={patient} />,
            path: 'consents',
          },
        ]
      : []),
  ];

  const { currentTab, onChangeTab } = useTabs(
    PATIENT_TABS.filter((tab) => pathuser == tab.path)[0].value
  );

  return (
    <>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        onChange={onChangeTab}
      >
        {PATIENT_TABS.map((tab) => (
          <Tab
            component={Link}
            to={`/dashboard/crs/patient/${patient.id}/${tab.path}`}
            disableRipple
            key={tab.value}
            label={capitalCase(tab.value)}
            value={tab.value}
          />
        ))}
      </Tabs>

      {PATIENT_TABS.map((tab) => {
        const isMatched = tab.value === currentTab;
        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
      })}
    </>
  );
}

function PatientDetailsView({ patient, onRefresh }: PatientDetails) {
  const { i18n } = useLocales();

  const valueSetsToRetrieve = ['patient-identifier-types', 'ph-gender-identity'];

  const [valueSets] = useValueSets({
    filter: {
      _id: valueSetsToRetrieve.join(','),
    },
    map: ValueSetWrapper,
  });

  const [patientIdentifierTypesVS, genderIdentityVS] = useMemo(
    () =>
      valueSetsToRetrieve.map((valueSet) => valueSets.find(({ id }) => id === valueSet) ?? null),
    [valueSets]
  );

  const patientIdentifierTypes = patientIdentifierTypesVS?.asList?.() ?? [];

  const [, { update: updatePatient }] = usePatient(patient.id!, { map: PatientWrapper });

  const onUpdateIdentifiers = useCallback(
    async (newIdentifiers: Identifier[]) => {
      await updatePatient(
        produce(patient!, (draft) => {
          draft.identifier = newIdentifiers;
        })
      );
    },
    [patient]
  );

  const handleOnAddressesSave = async (addresses) => {
    await updatePatient(
      produce(patient!, (draft) => {
        draft.address = addresses;
      })
    );
  };

  return (
    <TwoColumnLayout>
      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.PERSONAL_DETAILS.VIEW] }) ? (
        <Section>
          <PersonalDetails patient={patient} genderIdentityVS={genderIdentityVS} />
        </Section>
      ) : (
        <></>
      )}
      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONTACT_DETAILS.VIEW] }) ? (
        <Section>
          <ContactDetails patient={patient} />
        </Section>
      ) : (
        <></>
      )}

      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.ADDRESS_DETAILS.VIEW] }) ? (
        <div style={{ width: '100%', paddingLeft: '10px', paddingTop: '10px' }}>
          <ExpandableCard
            title={i18n('patients.details.addressDetails.title', 'crs')}
            sxProp={{ m: 1 }}
          >
            <NewAddressDetails
              addressess={patient?.address ?? []}
              onAddressesSave={handleOnAddressesSave}
              permissions={{
                isAllowedToAdd: checkAclValidation({
                  acls: [crsAcls.CRS.PATIENT.ADDRESS_DETAILS.ADD],
                }),
                isAllowedToEdit: checkAclValidation({
                  acls: [crsAcls.CRS.PATIENT.ADDRESS_DETAILS.EDIT],
                }),
                isAllowedToDelete: checkAclValidation({
                  acls: [crsAcls.CRS.PATIENT.ADDRESS_DETAILS.DELETE],
                }),
              }}
            />
          </ExpandableCard>
        </div>
      ) : (
        <></>
      )}

      {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.IDENTIFIER_DETAILS.VIEW] }) ? (
        <div style={{ width: '100%', paddingLeft: '10px', paddingTop: '10px' }}>
          <ExpandableCard
            title={i18n('patients.details.identifierDetails.title', 'crs')}
            sxProp={{ m: 1 }}
          >
            <IdentifierDetails
              identifiers={patient?.identifier ?? []}
              catalog={patientIdentifierTypes} 
              systemVS={patientIdentifierTypesVS?.getConceptSystem()}
              onUpdateIdentifiers={onUpdateIdentifiers}
              permissions={{
                isAllowedToAdd: checkAclValidation({
                  acls: [crsAcls.CRS.PATIENT.IDENTIFIER_DETAILS.ADD],
                }),
                isAllowedToEdit: checkAclValidation({
                  acls: [crsAcls.CRS.PATIENT.IDENTIFIER_DETAILS.EDIT],
                }),
                isAllowedToDelete: checkAclValidation({
                  acls: [crsAcls.CRS.PATIENT.IDENTIFIER_DETAILS.DELETE],
                }),
                isAllowedToViewHidden: checkAclValidation({
                  acls: [crsAcls.CRS.PATIENT.IDENTIFIER_DETAILS.HIDDEN.VIEW],
                }),
                isAllowedToEditHidden: checkAclValidation({
                  acls: [crsAcls.CRS.PATIENT.IDENTIFIER_DETAILS.HIDDEN.EDIT],
                }),
                isAllowedToDeleteHidden: checkAclValidation({
                  acls: [crsAcls.CRS.PATIENT.IDENTIFIER_DETAILS.HIDDEN.DELETE],
                }),
              }}
            />
          </ExpandableCard>
        </div>
      ) : (
        <></>
      )}

      {/* <Section>
        <OtherDetails />
      </Section> */}
    </TwoColumnLayout>
  );
}
