import { useEffect, useMemo, useState } from "react";
import { Button, Grid, List, ListItem, Typography } from '@mui/material';
import Map from './Map';
import { Marker, useJsApiLoader } from "@react-google-maps/api";
import { getLatitudeAndLongitude } from "src/sections/crs/common/common-utils";
import { CheckCircleOutline as CheckIcon } from '@mui/icons-material';
import { getIdentifier } from "src/utils/address";

const AddressList = ({
  addresses,
  saveSelectedAddress,
  title,
  configurations,
  addressSaved
}: any) => {
  const [addressList, setAddressList] = useState(addresses || []);
  const [selectedMarker, setSelectedMarker] = useState<string>();
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  useEffect(()=> {
    setAddressList(addresses);
  }, [addresses]);

  const JsOptions = useMemo<any>(
    () => ({
      id: 'google-map-script',
      googleMapsApiKey: configurations?.googleMapsApiKey || '',
      libraries: ['places'],
    }),
    []
  );
  const { isLoaded } = useJsApiLoader(JsOptions);

  return (
    <Grid container sx={{ ml: 0 }}>
      <Grid
        item
        sm={5}
      >
        <List>
          {addressList?.length > 0 ? (
            addressList?.map((address: any, index: number) => {
              const identifier = getIdentifier(address);
              const addressIdentifierWorkflowData = getIdentifier(addressSaved);
              const showIconSelected = identifier === addressIdentifierWorkflowData;

              return (
                <ListItem
                  divider
                  key={identifier}
                >
                  <Grid item md={1}>
                    <span
                      style={{
                        color: '#fff',
                        backgroundColor: getLabelColor(address),
                        padding: 2,
                      }}
                    >
                      {alphabet[index]}
                    </span>
                  </Grid>
                  <Grid item md={saveSelectedAddress ? 8 : 12} paddingLeft={1}>
                    <Typography variant="subtitle2">{address.name}</Typography>
                    <Typography variant="body2">
                      {Array.isArray(address.line) ? address.line.join(', ') : address.line}
                    </Typography>
                    <Typography variant="body2">
                      {address.city}, {address.district}, {address.state} {address.country}. {address.postalCode}
                    </Typography>
                  </Grid>
                  {(saveSelectedAddress && !showIconSelected)  ? (
                    <Grid item md={3}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => saveSelectedAddress(address)}
                      >
                      {title ? title : "Select This"}
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item md={3}>
                      <CheckIcon fontSize="large" sx={{ color: 'green', mx: 3 }} />
                    </Grid>
                  )
                }
                  
                </ListItem>
              )
            })
          ) : (
            <ListItem>
              <Typography>No addresses available</Typography>
            </ListItem>
          )}
        </List>
      </Grid>

      <Grid item sm={7}>
        <Map 
          zoom={4} 
          isLoaded={isLoaded} 
          center={{
            lat: 38.3966908,
            lng: -99.525901
          }}
        >
          {addressList?.map((address: any, i: number) => {
            const marker = {
              id: address?.extension?.[0]?.extension?.find((a: any) => a.url === 'identifier')
                ?.valueString,
              latitude: getLatitudeAndLongitude(address).latitude,
              longitude:getLatitudeAndLongitude(address).longitude,
            };
            if (
              marker.latitude &&
              address?.extension?.[0]?.extension?.find((a: any) => a.url === 'valid')?.valueBoolean
            ) {
              const iw = 70,
                ih = 94;
              return (
                window.google && (
                  <Marker
                    key={marker.id}
                    position={{
                      lat: parseFloat(marker.latitude.toString()),
                      lng: parseFloat(marker.longitude.toString()),
                    }}
                    onClick={() => setSelectedMarker(alphabet[i])}
                    icon={{
                      url: `https://waqi.info/mapicon/${alphabet[i]}.30.png`,
                      anchor: new window.google.maps.Point(iw / 4, ih / 2 - 5),
                      size: new window.google.maps.Size(iw / 2, ih / 2),
                      scaledSize: new window.google.maps.Size(iw / 2, ih / 2),
                    }}
                  />
                )
              );
            }
          })}
        </Map>
      </Grid>
    </Grid>
  );
};

export const getLabelColor = (address: any, colors?: any): string => {
  const hasExtension = address?.extension?.[0]?.extension !== undefined;
  const isValid = address?.extension?.[0]?.extension?.find((a: any) => a.url === 'valid')?.valueBoolean;

  if (hasExtension && isValid) return colors?.valid ? colors?.valid : '#009764';
  if (hasExtension) return colors?.invalid ? colors?.invalid : '#e5881e';

  return colors?.noExtension ? colors?.noExtension : '#db1919';
};

export default AddressList;