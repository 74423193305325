import { useEffect, useMemo, useState } from "react";
import { getAgeGroup } from "src/utils/fhir";
import { getMonthsSince } from "src/utils/formatTime";
import { CustomReference } from "src/@types/nat/request";
import { TaskWrapper } from "src/@nicheaim/fhir-base/wrappers/Task";
import { PatientWrapper } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { RelatedPersonWrapper } from "src/@nicheaim/fhir-base/wrappers/RelatedPerson";
import { ServiceRequestWrapper, WrappedServiceRequest } from "src/@nicheaim/fhir-base/wrappers/ServiceRequest";
import { useCodeSystems, usePatient, useRelatedPersons, useServiceRequests, useTasks, useValueSet } from "src/@nicheaim/fhir-react";
import { ValueSetWrapper } from "src/@nicheaim/fhir-base/wrappers/ValueSet";
import { referralService } from "src/crs/referral/services";

const useRequestValidation = ( patientId: string ): { 
  ageRangeValidation: boolean | null,
  planDefinition: any | null,
  ageRangePatient: any,
  requestActive: WrappedServiceRequest | null,
  relatedPersons: CustomReference[] | null,
  isLoading: boolean,
  handleRefreshValidation: any,
} => {
  const [planDefinition, setPlanDefinition] = useState<any>(null);
  const [isLoadingPlanDefinition, setIsLoadingPlanDefinition] = useState(true);

  const [ patient, { 
    refresh: refreshPatient, 
    isLoading: isLoadingPatient 
  } ] = usePatient(patientId, { map: PatientWrapper, autofetch: !!patientId });

  const [ serviceRequest, { 
    refresh: refreshServiceRequest, 
    isLoading: isLoadingServiceRequest 
  } ] = useServiceRequests({
    filter: {
      status: 'active,on-hold,draft',
      patient: patient?.id,
      _sort: '-authored',
    },
    map: ServiceRequestWrapper,
    autofetch: !!patient,
  });

  const [ codeSystemAgeRange, { isLoading: isLoadingCS } ] = useCodeSystems({
    filter: { name: 'Needs Assesment Tool Age Groups' }
  });

  const [requestIntentVS, { 
    isLoading: isLoadingRequestIntent 
  }] = useValueSet('request-intent', { map: ValueSetWrapper });
  const [referralServicesInternal, { 
    isLoading: isLoadingReferralServices 
  }] = useValueSet('ph-referral-services-internal', { map: ValueSetWrapper });

  const patientAgeRange = useMemo(() => 
    patient?.birthDate && getAgeGroup(getMonthsSince(new Date(patient.birthDate)), codeSystemAgeRange?.[0])
  , [patient, codeSystemAgeRange]);

  useEffect(() => {
    const fetchPlanDefinition = async () => {
      setIsLoadingPlanDefinition(true);
      try {
        const mapPlanActivityDefinition = 
          await referralService.getPlanActivityDefinition(requestIntentVS, referralServicesInternal);
        setPlanDefinition(
          mapPlanActivityDefinition?.find(e => e?.reference === "PlanDefinition/nat-outbound-referral-internal") ?? []
        );
      } catch (error) {
        console.error("Error fetching PlanDefinition:", error);
      } finally {
        setIsLoadingPlanDefinition(false);
      }
    };
    
    if (requestIntentVS && referralServicesInternal) {
      fetchPlanDefinition();
    }
  }, [patient, requestIntentVS, referralServicesInternal]);

  const [relatedPersons, { 
    refresh: refreshRelatedPersons, 
    isLoading: isLoadingRelatedPersons 
  }] = useRelatedPersons({
    filter: { patient: patient?.id },
    map: RelatedPersonWrapper,
    autofetch: !!patient,
  });

  const [tasks, { refresh: refreshTasks, isLoading: isLoadingTasks }] = useTasks({
    filter: {
      code: 'completed-nat-assessment',
      'based-on': serviceRequest?.[0]?.id,
      status: 'requested,received,accepted,ready,in-progress,on-hold,draft',
      _sort: '-authored-on'
    },
    map: TaskWrapper,
    autofetch: !!serviceRequest
  });

  const owners: CustomReference[] = useMemo(() => {
    if (!relatedPersons || !tasks || !patient) return [];

    return relatedPersons.map(x1 => ({
      reference: `RelatedPerson/${x1.id}`,
      display: x1?.getFullName() ?? undefined,
      contact: x1?.getPrimaryEmail()?.value || x1?.getPrimaryPhone() ? true : false,
      tasks: tasks?.filter(e => e?.owner?.reference === `RelatedPerson/${x1.id}`)
    })).concat({
      reference: `Patient/${patient?.id}`,
      display: patient?.getFullName() ?? undefined, 
      contact: patient?.getPrimaryEmail()?.value || patient?.getPrimaryPhone() ? true : false,
      tasks: tasks?.filter(e => e?.owner?.reference === `Patient/${patient?.id}`)
    });
  }, [relatedPersons, tasks, patient]);

  const planDefinitionCorrespondsToActiveRequest = useMemo(() => {
    if (serviceRequest?.length > 0 && planDefinition) {
      const planDefinitionReference = serviceRequest?.[0]?.instantiatesCanonical?.[0] ?? '';
      return planDefinitionReference === planDefinition?.reference;
    }
    return false;
  }, [serviceRequest, planDefinition]);

  const isLoading = useMemo(() => 
    isLoadingPatient || 
    isLoadingServiceRequest || 
    isLoadingPlanDefinition || 
    isLoadingRelatedPersons || 
    isLoadingTasks ||
    isLoadingRequestIntent ||
    isLoadingReferralServices ||
    isLoadingCS, 
  [
    isLoadingPatient, 
    isLoadingServiceRequest, 
    isLoadingPlanDefinition, 
    isLoadingRelatedPersons, 
    isLoadingTasks,
    isLoadingRequestIntent,
    isLoadingReferralServices,
    isLoadingCS
  ]);

  const handleRefreshValidation = () => {
    refreshPatient();
    refreshServiceRequest();
    refreshRelatedPersons();
    refreshTasks();
  };

  const responseValidation = useMemo(() => {
    if (isLoading) {
      return {
        ageRangeValidation: null,
        planDefinition: null,
        ageRangePatient: null,
        requestActive: null,
        relatedPersons: null,
        isLoading: true,
        handleRefreshValidation
      };
    }

    return {
      ageRangeValidation: planDefinitionCorrespondsToActiveRequest ?? null,
      planDefinition: planDefinition ?? null,
      ageRangePatient: patientAgeRange?.[0] ?? null,
      requestActive: serviceRequest?.[0] ?? null,
      relatedPersons: owners,
      isLoading: false,
      handleRefreshValidation
    };
  }, [patient, isLoading, planDefinitionCorrespondsToActiveRequest, planDefinition, patientAgeRange, serviceRequest, owners]);

  return responseValidation;
};

export default useRequestValidation;