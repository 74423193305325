import {
  CircularProgress,
  createTheme,
  Dialog,
  DialogTitle,
  useTheme,
  Backdrop,
  Stack,
  DialogActions,
  Autocomplete,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import useLocales from '../../../../../hooks/useLocales';
import { useState } from 'react';
import useAddEntityRequestStates from '../../../../../hooks/useAddEntityRequestStates';
import { ValueSetComposeIncludeConcept } from '../../../../../nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import { isEmpty } from 'lodash';

type Props = {
  open: boolean;
  categories: any[];
  // patient: WrappedPatient | null;
  // externalOption?: string;
  // handleRefresh?: VoidFunction;
  // handlerInitiateHJRequest?: (data: any) => Promise<any>;
  onSelect: (selectedCategory: ValueSetComposeIncludeConcept) => void;
  onCancel: VoidFunction;
};

export default function PatientGenericServiceRequestCategorySelector({
  open,
  categories,
  onSelect,
  // patient,
  // externalOption,
  // handleRefresh,
  // handlerInitiateHJRequest,
  onCancel,
}: Props) {
  const { i18n } = useLocales();
  const defaultTheme = useTheme();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [{ error }, { setError }] = useAddEntityRequestStates();

  const [selectedCategory, setSelectedCategory] = useState<ValueSetComposeIncludeConcept | null>(
    null
  );

  const theme = createTheme({
    palette: {
      ...defaultTheme.palette,
      primary: {
        main: '#b76e00',
        contrastText: 'white',
        lighter: '',
        darker: '',
      },
    },
  });

  const optionTitle = i18n('nat.request.select.titleRequest', 'translations');

  const handleClose = () => {
    onCancel();
    // setRecipient(null);
    // setNatRequestValidation(null);
    setError(null);
    setSelectedCategory(null);
    // setShowWarning(false);
    // setDisableRecipient(false);
  };

  const handleSubmit = async () => {
    console.log('DEBUG handleSubmit selectedCategory', selectedCategory);
    if (selectedCategory) {
      onSelect(selectedCategory);
      handleClose();
    }
  };

  return (
    <Dialog open={open} maxWidth="md">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <DialogTitle sx={{ py: 2 }}>Select {optionTitle} category</DialogTitle>

      <Grid
        container
        spacing={1}
        // direction="column"
        // alignItems="center"
        // justifyContent="center"
        // sx={{ width: '55vw' }}
      >
        <Grid item xs={12} mx={2}>
          {/* <GridSection> */}
          {/* <GridItem> */}
          <Autocomplete
            fullWidth
            disabled={!!!categories}
            value={selectedCategory}
            onChange={(_: React.SyntheticEvent, category) => {
              setSelectedCategory(category);
            }}
            options={categories}
            getOptionLabel={({ display }: ValueSetComposeIncludeConcept) => display ?? ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label={i18n('referral.details.category', 'crs')}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {!categories ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          {/* </GridItem> */}
          {/* </GridSection> */}
        </Grid>
      </Grid>

      <Stack alignItems="flex-end">
        <DialogActions>
          <Button variant="text" color="inherit" onClick={handleClose}>
            {i18n('cancel')}
          </Button>
          {/* {natRequestValidation?.ageRangePatient?.code && natRequestValidation?.planDefinition && ( */}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isEmpty(selectedCategory) ? true : false}
            onClick={handleSubmit}
          >
            Create {optionTitle}
          </Button>
          {/* )} */}
        </DialogActions>
      </Stack>
    </Dialog>
  );
}
