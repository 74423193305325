import useTenantConfigData from "src/hooks/useTenantConfigData";
import { useMemo } from "react";
import AddAddress from "./AddAddress";
import { CircularProgress } from "@mui/material";

const AddAddressLoading = ({
  handleClose,
  handleSave,
  isEditable,
  externalAddress,
  children,
  disabled
}: any) => {
  const { configurations } = useTenantConfigData();

  const configuration = useMemo(() => configurations ?? null,[configurations])

  if(!configuration){
    return <CircularProgress color="inherit" size={20} />
  }
  
  return (
    <AddAddress 
      handleClose={handleClose}
      handleSave={handleSave}
      isEditable={isEditable}
      externalAddress={externalAddress}
      children={children}
      disabled={disabled}
      configurations={configuration}
    />
  )
};

export default AddAddressLoading;