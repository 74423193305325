import moment from 'moment';
import { isEmpty } from 'lodash';
import { DatePicker } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import useLocales from 'src/hooks/useLocales';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ResourceMapping, addressesSorted, updateResource } from 'src/sections/crs/helpers/indicent';
import { Address } from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import AddressList from 'src/sections/crs/referral/components/workflow-step/AddressChecklistItem/AddressList';
import { Accordion, AccordionSummary, Box, Button, Card, Grid, ListItem, Stack, TextField, Typography } from "@mui/material";
import AddAddressLoading from 'src/sections/crs/referral/components/workflow-step/AddressChecklistItem/AddAddressLoading';

type Props = {
  resource: any;
  dataIncidentStatus: boolean;
  configurations: any;
  refresh: VoidFunction;
};

export function AddressIncident({
  resource,
  dataIncidentStatus,
  configurations,
  refresh
}: Props){

  const { i18n } = useLocales();
  const { enqueueSnackbar } = useSnackbar();

  const [addresses, setAddresses] = useState<Address[]>([]);
  const [addressEdit, setAddressEdit] = useState();
  const [addAddressTabOpen, setAddAddressTabOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    setAddresses([]);
    if (resource?.address) {
      const getAddresses = addressesSorted(resource?.address, ['valid', 'period.start']);
      setAddresses(getAddresses);
    }
  }, [resource]);

  const validateDateFields = (start: any, end: any) => {
    let isValid = true;
    const startDate = moment(start ?? null);
    const endDate = moment(end ?? null);
    if (startDate.isValid() && endDate.isValid()) {
      if (startDate.isAfter(endDate)) {
        setError(true);
        isValid = false;
      }
    }
    return isValid;
  };

  const handleFixAddress = async (data: any) => {

    try {
      if (!validateDateFields(startDate, endDate)) return;
      if(isEmpty(data?.id)) {
        enqueueSnackbar('Please verify the address in the “Enter Address” field', { variant: 'error' });
        return;
      }

      const period = (startDate || endDate) ? 
        { period: { 
          ...(startDate && { start: startDate }), 
          ...(endDate && { end: endDate }) } 
        } : {};

      const setData = { ...data, ...period };
      
      const findAddress = addresses?.find((item: Address) => item?.id === setData?.id);
      
      let setAddresses: Address[] = [];
      
      if (findAddress) {
        setAddresses = addresses?.map((item: Address) => {
          const address: Address = item?.id === setData?.id ? setData : item;
          return address;
        });
      } else {
        setAddresses = [...addresses, setData];
      }

      setAddresses?.map((item: any) => {
        if (Number.isInteger(item?.id)) delete item?.id;
        delete item?.valid;
        return item;
      });
    
      const payload: ResourceMapping = {
        ...resource, 
        address: setAddresses
      };

      const response = await updateResource(
        resource?.resourceType,
        payload
      );

      enqueueSnackbar('Updated succesfully');
      if(response) refresh();
      
      handleCancel();
    } catch (err) {
      enqueueSnackbar('An error has occurred', { variant: 'error' });
    }
  };

  const handleCancel = () => {
    setAddAddressTabOpen(false);
    setStartDate(null);
    setEndDate(null);
  };

  const handlerOpenAddressEdit = (data: any) => {
    if(!data) return;
    if(data?.period?.start) setStartDate(data?.period?.start);
    if(data?.period?.end) setEndDate(data?.period?.end);
    setAddressEdit(data);
    setAddAddressTabOpen(true);
  }

  return (
    <Card sx={{ mt: 2 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{ py: 2 }}
        >
          Addresses
        </AccordionSummary>
          <Grid container alignItems="center" sx={{ px: 2 }}>
            <Grid item xs={6}>
              <Stack direction="row" spacing={2}>
                <Button
                  disabled={dataIncidentStatus}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setAddAddressTabOpen(true)
                    setAddressEdit(undefined);
                  }}>
                  Add Address
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row" justifyContent="end" spacing={2}>
                <Legend text="Valid" color="#009764"/>
                <Legend text="Invalid" color="#db1919"/>
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={1} sx={{ m: 2, overflow: 'auto' }}>
            {addAddressTabOpen  ? 
              <AddAddressLoading
                disabled={dataIncidentStatus}
                externalAddress={addressEdit}
                isEditable={true}
                handleClose={handleCancel}
                handleSave={handleFixAddress} 
                children={
                  <>
                    <ListItem>
                      <DatePicker
                        label={i18n('patients.details.addressDetails.startDate', 'crs')}
                        value={startDate}
                        onChange={(value) => {
                          setStartDate(value);
                          setError(false);
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            {...params}
                            fullWidth
                            error={error}
                            helperText={error && `Start Date can't be greater than End Date`}
                          />
                        )}
                      />
                    </ListItem>
                    <ListItem>
                      <DatePicker
                        label={i18n('patients.details.addressDetails.endDate', 'crs')}
                        value={endDate}
                        onChange={(value) => {
                          setEndDate(value);
                          setError(false);
                        }}
                        renderInput={(params) => <TextField variant="outlined" {...params} fullWidth />}
                      />
                    </ListItem>
                  </>
                }
              />
            :
              <AddressList 
                addresses={addresses} 
                saveSelectedAddress={handlerOpenAddressEdit}
                title="Edit"
                configurations={configurations}
              />
            }
            </Stack>
      </Accordion>
    </Card>
  )
}

type LegendProps = {
  text: string;
  color: string;
};

function Legend({ text, color }: LegendProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box
        sx={{
          width: 16,
          height: 16,
          bgcolor: `${color}`
        }}
      />
      <Typography sx={{ color: 'text.secondary' }}>{text}</Typography>
    </Stack>
  );
}
