import { Button, ButtonProps, styled } from '@mui/material';

const ItemButton = styled(Button)<ButtonProps>(() => ({
  color: "#fff",
  minWidth: "190px",
  backgroundColor: "#1890FF",
  "&:hover": {
    backgroundColor: "#1890FF",
  },
}));

const ButtonComponent = (props: any) => {
  const {
    record,
    checklistItem,
    refreshChecklistHandler,
    refreshRecordHandler,
  } = props?.others;

  return (
    <div className={`btn-${props.elementName.toLowerCase()}`} style={{ marginBottom: 10 }}>
      <ItemButton 
        fullWidth
        id={props?.id}
        onClick={ () =>
          props?.others?.handler({
          record,
          checklistItem,
          refreshChecklistHandler,
          refreshRecordHandler,
        }) }
      >
        {props.elementName} {checklistItem?.isRequired && "*"}
        {(props?.others?.data && typeof props?.others?.data === 'string') && props?.others?.data}
      </ItemButton>
    </div>
  );
};

export default ButtonComponent;