import { PATH_DASHBOARD } from "src/routes/paths";
import { taskService } from "src/nat/task/services";
import Viewer from "src/sections/crs/common/Viewer";
import { useEffect, useMemo, useRef, useState } from "react";
import { ReportResources } from "src/@types/nat/report";
import { getPrintableTask } from "src/sections/nat/helpers/report";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import useTaskServiceRequestQuestionnaire from "src/hooks/useTaskServiceRequestQuestionnaire";
import { Backdrop, Button, CircularProgress, Divider, Drawer, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import useTenantConfigData from "src/hooks/useTenantConfigData";
import axiosFhirInstance from "src/application/adapters/out/repositories/axiosFhirInstance";
import { Bundle, Task } from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";

type Props = {
  patients: WrappedPatient | null;
  isOpen: boolean;
  handlerIsOpen: Function;
  handleReviewHJRequest: () => Promise<any>;
}

export default function ReviewNatReportItem({
  patients,
  isOpen,
  handlerIsOpen,
  handleReviewHJRequest,
}: Props) {
  const { configurations } = useTenantConfigData();

  const [file, setFile] = useState<any>();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hasFetched = useRef(false);
  const [tasks, setTasks] = useState<any>([]);
  const [isTasksLoading, setIsTasksLoading] = useState(false);

  useEffect(() => {
    if (!patients?.id || !isOpen) return;

    const fetchData = async () => {
      try {
        setIsTasksLoading(true);
        const response = await axiosFhirInstance.get(
          `Task?subject=${patients?.id}&code=completed-nat-assessment&status=completed&_sort=-authored-on`
        );
        const taskPatient =
          (response?.data as Bundle<Task[]>)?.entry?.map((entry) => entry?.resource) ?? [];
        setTasks(taskPatient);
      } catch (error) {} finally {
        setIsTasksLoading(false);
      }
    };

    fetchData();
  }, [patients, isOpen]);

  const {
    report,
    patient,
    planDefinition,
    serviceRequest,
    isLoading: isLoadingReport,
  } = useTaskServiceRequestQuestionnaire(tasks?.[0]?.id ?? '');

  const getTask: ReportResources | undefined = useMemo(
    () => report?.find((item) => item?.task?.id === tasks?.[0]?.id),
    [tasks, report]
  );

  const isDataLoading = useMemo(
    () => isTasksLoading || isLoadingReport || isLoading,
    [isTasksLoading, isLoadingReport, isLoading]
  );

  useEffect(() => {
    if (isOpen) {
      if (!isDataLoading && report?.length && getTask && !hasFetched.current) {
        hasFetched.current = true;
        getPrintableReport();
      }
    }
  }, [isOpen, isDataLoading, tasks, report, getTask]);

  const getPrintableReport = async () => {
    try {
      if (getTask) {
        setIsLoading(true);
        setFile(null);

        const bodyPrintable = getPrintableTask(
          {
            patient,
            task: getTask?.task,
            serviceRequest,
            planDefinition,
            questionnaire: getTask?.questionnaire,
            questionnaireResponse: getTask?.questionnaireResponse,
            measureReport: getTask?.measureReport,
            ownerTask: getTask?.ownerTask,
            requesterTask: getTask?.requesterTask,
            questionnaireAnswerMap: [],
          },
          false,
          configurations
        );

        const response = await taskService.getPrintable(bodyPrintable);

        const blob = new Blob([response?.data], { type: 'application/pdf' });
        setFile(blob);
      }
    } catch (error) {
      console.error('Error generating printable report:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlerStep = async () => {
    setOpenBackdrop(true);

    try {
      await handleReviewHJRequest();
    } catch (error) {}

    handlerIsOpen();
    setOpenBackdrop(false);
  };

  return (
    <Drawer
      PaperProps={{
        sx: { width: "60%" },
      }}
      anchor="right"
      open={isOpen}
      onClose={() => {
        handlerIsOpen(false);
      }}
    >
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <List component="nav">
        <ListItem>
          <Grid container>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={handlerStep}
              >
                Review HJ Report
              </Button>
              <Button
                onClick={() => {
                  handlerIsOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!serviceRequest?.id}
                onClick={() => window.open(`${PATH_DASHBOARD.nat.task}/${tasks?.[0]?.id}`, '_blank')}
              >
                Open HJ Report
              </Button>
            </Stack>
          </Grid>
        </ListItem>
      </List>
      <Divider sx={{ marginBottom: 2 }} />
      {isDataLoading ? (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: 200 }}>
          <CircularProgress color="inherit"/>
        </Grid>
      ) : file ? (
        <Viewer file={file} typeResource="blob" />
      ) : (
        <Grid container justifyContent="center" alignItems="center" sx={{ height: 200 }}>
          <Typography variant="h6" color="textSecondary">
            No report available
          </Typography>
        </Grid>
      )}
    </Drawer>
  );
}
