import { capitalCase } from "change-case";
import { useEffect, useState } from "react";
import { Upload } from "src/sections/crs/common";
import { useFormContext } from "react-hook-form";
import Scrollbar from "src/components/Scrollbar";
import Viewer from "src/sections/crs/common/Viewer";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import useTenantConfigData from "src/hooks/useTenantConfigData";
import { Box, Card, Checkbox, Link, Stack, Tab } from "@mui/material";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { WrappedDocumentReference } from "src/@nicheaim/fhir-base/wrappers/DocumentReference";

type Props = {
  label?: string;
  restrictFiles?: boolean;
  docsList?: boolean;
  patient: WrappedPatient | null;
  docRelated?: any;
  documentReferences: WrappedDocumentReference[] | null;
};
 
 export default function UploadFiles ({ label, restrictFiles, docsList, docRelated, documentReferences, patient }: Props) {
  const { configurations } = useTenantConfigData();
  
  const { setValue } = useFormContext();

  const [ data, setData ] = useState(null);

  const [ valueTab, setValueTab ] = useState('1');

  const [ showViewer, setShowViewer ] = useState(false);

  const [ selected, setSelected ] = useState<string[]>(docRelated);

  const onSelected = (id:string) => {
    const selectedIndex = selected.indexOf(id);

    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    setValue('documents',newSelected);
  };

  const handleClick = (documentSelected:any, )=>{
    setShowViewer(true);
    setData(documentSelected);
  }

  useEffect(() => {
    if(docsList){
      setValueTab('2');
    }
  },[docsList]);

  return (
    <>
      <Card sx={{ m: 1 }}>
        <TabContext value={valueTab}>
          <Box sx={{ px: 3 }}>
            <TabList onChange={(e, value) => setValueTab(value)} 
            >
              <Tab 
                disableRipple 
                value="1" 
                label={`${capitalCase(label || '')} Attached Docs`} 
                onClick={() => setShowViewer(false)}
              />
              <Tab 
                disableRipple
                value="2"
                label="Manual Uploads"
                onClick={() => setShowViewer(false)}
                sx={{ '& .MuiTab-wrapper': { whiteSpace: 'nowrap' } }}
              />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ p: 3 }}>
            <Stack spacing={2}>
              <Scrollbar sx={{ height: 200, width: 600 }}>
                {documentReferences?.map((item, index:number)=> {
                  return(
                    <Stack direction="row" spacing={1} alignItems="center" key={item.id}>
                      <Checkbox 
                        checked={selected?.includes(item.id || '')} 
                        onClick={() => onSelected(item.id || '')} 
                      />
                      <Link 
                        variant="subtitle2" 
                        color="#1890FF" 
                        underline="always" 
                        onClick={() => handleClick(item)}
                      >
                        {item.content[0]?.attachment?.title}
                      </Link>
                    </Stack>
                  )
                })}
              </Scrollbar>
            </Stack>
          </TabPanel>
          <TabPanel value="2" sx={{ p: 3 }}>
            <Stack direction="column" spacing={1} alignItems="center" justifyContent="center">
              <Upload restrictFiles={restrictFiles} />
            </Stack>
          </TabPanel>
        </TabContext>
      </Card>
      {showViewer && ( 
        <Viewer 
          documentReference={data} 
          typeResource="documentReference" 
          patientId={patient?.getMRN(configurations?.mpi?.systemMrn)?.value} 
        />
      )}
    </>
  )
}