import { PATH_DASHBOARD } from 'src/routes/paths';
import useTenantConfigData from './useTenantConfigData';

export enum Event {
  CREATED = 'Created',
  SEARCHED= 'Searched',
  CARD_CLICKED = 'Card Clicked',
}

const useRouteHanderByTenant = () => {
  const { componentsData } = useTenantConfigData();
  const { patientLandingPageRoute } = componentsData;

  function redirectRouteAfterCreatePatient(fhirPatientId: string, event: string, locationPathName?: string) {
    if(event==Event.CREATED||event==Event.SEARCHED){
      const setPatientLandingPageRoute = locationPathName ? 
        patientLandingPageRoute?.[locationPathName] ?? null : null;
  
      if (setPatientLandingPageRoute && setPatientLandingPageRoute !== '') {
        return setPatientLandingPageRoute.replace(`:patientId`, fhirPatientId);
      }

      if(patientLandingPageRoute?.default)
        return patientLandingPageRoute?.default.replace(`:patientId`, fhirPatientId)
    }

    return PATH_DASHBOARD.crs.patient.details.forId(fhirPatientId || '');
  }

  return {
    redirectRouteAfterCreatePatient,
    Event
  };
};

export default useRouteHanderByTenant;
