import Cookies from 'js-cookie';
import { TenantI, UserOrganizationSimpleI } from 'src/utils/auth-utils';
import { urlBackNestApp } from '../routes/urls';
import { EnableLogoutFAAResponseI } from './response-types';

let defaultHeaders = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${Cookies.get('careflowToken')}`,
};

function getAccessToken(): string | undefined {
  return Cookies.get('careflowToken');
}

export function getDefaultHeaders() {
  const token = getAccessToken();
  defaultHeaders.Authorization = `Bearer ${token}`;
  return defaultHeaders;
}

async function get<T>(
  path: string,
  args: RequestInit = { method: 'GET', headers: getDefaultHeaders() }
): Promise<T> {
  const response = await fetch(path, args);
  return response.json();
}

async function post<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: getDefaultHeaders(),
  }
): Promise<T> {
  const response = await fetch(path, args);
  return response.json();
}

async function put<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: getDefaultHeaders(),
  }
): Promise<T> {
  const response = await fetch(path, args);
  return response.json();
}

async function patch<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: 'PATCH',
    body: JSON.stringify(body),
    headers: getDefaultHeaders(),
  }
): Promise<T> {
  const response = await fetch(path, args);
  return response.json();
}

const Domain = {
  getBackendUrl: async (domain: string) => {
    const path = `${urlBackNestApp}/domain/backend-url?frontend-domain=${domain}`;
    const args: RequestInit = { method: 'GET' };
    const response = await fetch(path, args);
    return response.json();
  },
};

const Tenant = {
  getAll: () => get<TenantI[]>(`${urlBackNestApp}/auth-tenant/get-tenants`),
  isLogoutFromAllAppsEnabled: () =>
    get<EnableLogoutFAAResponseI>(
      `${urlBackNestApp}/auth/is-logout-from-all-apps-enabled-for-frontend`
    ),
};

const Organization = {
  getAll: () => get<any>(`${urlBackNestApp}/auth-tenant/get-organizations`),
};

const User = {
  getAllUsers: () => get<any>(`${urlBackNestApp}/user/getUsers`),
  getUsersByOrgAndRole: (orgIds: string[], roleIds: string[]) => get<any>(`${urlBackNestApp}/user/by-organization-role?organization_uuids=${orgIds}&role_codes=${roleIds}`),
  getAllUsersByAcls: (acls: string[]) => get<any>(`${urlBackNestApp}/user/by-acls?acls=${acls}`),
  getRecord: (id: any) => get<any>(`${urlBackNestApp}/user/workflow?id=${id}`),
  customUpdate: (data: any) => put<any>(`${urlBackNestApp}/user/workflow`, data),
  getUserSessionsRedirectUrl: () => get<any>(`${urlBackNestApp}/auth/user-sessions`),
  getOrganizations: () =>
    get<UserOrganizationSimpleI[]>(`${urlBackNestApp}/auth/get-user-organizations`),
};

const Workflow = {
  getWorkflowByCode: (code: any) => get<any>(`${urlBackNestApp}/workflow/?code=${code}`),
  getWorkflowByType: (type: string) => get<any>(`${urlBackNestApp}/workflow/?type=${type}`),
  getTransitions: (workFlow: any) =>
    get<any>(`${urlBackNestApp}/workflow/transitions?workFlow=${workFlow}`),
  getInstance: (referenceId: any, referenceName: any) =>
    get<any>(
      `${urlBackNestApp}/workflow/instance/?referenceName=${referenceName}&referenceId=${referenceId}`
    ),
  dbAttribute: (data: any) => post<any>(`${urlBackNestApp}/workflow/instance/dbattribute`, data),
  assignOwner: (data: any) => post<any>(`${urlBackNestApp}/workflow/instance/assignOwner`, data),
  reAssignOwner: (data: any) =>
    post<any>(`${urlBackNestApp}/workflow/instance/reAssignOwner`, data),
  fhirAttribute: (data: any) =>
    post<any>(`${urlBackNestApp}/workflow/instance/fhirattribute`, data),
  updateWorkflowInstance: (data: any) =>
    put<any>(`${urlBackNestApp}/workflow/instance/update`, data),
};

const Record = {
  updateRecordByAttribute: (data: any) => put<any>(`${urlBackNestApp}/workflow/instance/`, data),
};

const Checklist = {
  getChecklistById: (data: any) => post<any>(`${urlBackNestApp}/checklist/allChecklist`, data),
  markItemCompleted: (data: any) => post<any>(`${urlBackNestApp}/checklist/completed`, data),
  getChecklistByCode: (code: any) => get<any>(`${urlBackNestApp}/checklist/code/?code=${code}`),
  checkItemsValid: (data: any) => post<any>(`${urlBackNestApp}/checklist/checkItemsValid`, data),
};

const Transition = {
  attempt: (data: any) => post<any>(`${urlBackNestApp}/transition/attempt/`, data),
  updateStage: (data: any) => put<any>(`${urlBackNestApp}/transition/stage/`, data),
};

const Activity = {
  getActivities: () => get<any>(`${urlBackNestApp}/ccm/activity/activities`),
};

const Catalog = {
  getCatalogByCode: (code: any) => get<any>(`${urlBackNestApp}/crs/catalog/?code=${code}`),
};

export const HttpAgent = {
  User,
  Workflow,
  Checklist,
  Transition,
  Record,
  Activity,
  Catalog,
  Tenant,
  Organization,
  Domain
};

export default HttpAgent;
