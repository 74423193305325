import moment from 'moment';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
import { ConditionForm } from './ConditionForm';
import { TableMoreMenu } from 'src/components/table';
import { Box, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import { WrappedPatient } from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { getCodingDisplayName } from 'src/sections/crs/helpers/common';
import { formatDateTimeToDefault } from 'src/utils/dates';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import crsAcls from 'src/utils/permissions/crs/crsAcls';

type Props = {
  row: any | null;
  patient: WrappedPatient | null;
  handleAppointment: (data: any) => Promise<any>;
};

export function Row({ row, patient, handleAppointment }: Props) {
  const [data, setData] = useState(null);
  const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleEdit = (row: any) => {
    handleCloseMenu();
    setData(row);
    setOpenModalEdit(true);
  };

  return (
    <>
      <TableRow>
        <TableCell>{row?.code?.coding[0]?.code}</TableCell>
        <TableCell>
          <Box sx={{ maxWidth: 300 }}>
            <Typography style={{ cursor: 'pointer' }} noWrap sx={{ fontSize: `0.75rem` }}>
              {getCodingDisplayName(row?.code)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell>{getCodingDisplayName(row?.category?.[0])}</TableCell>
        <TableCell>{formatDateTimeToDefault(row?.onsetPeriod?.start)}</TableCell>
        <TableCell>{formatDateTimeToDefault(row?.onsetPeriod?.end)}</TableCell>
        <TableCell>{row?.recordedDate && moment(row?.recordedDate).format('MM/DD/YYYY')}</TableCell>
        <TableCell>{row?.created && moment(row?.created).format('MM/DD/YYYY')}</TableCell>
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              checkAclValidation({ acls: [crsAcls.CRS.PATIENT.CONDITION.EDIT] })
                ? [
                    <MenuItem key={row?.id} onClick={() => handleEdit(row)}>
                      <Iconify icon={'mdi:pencil'} />
                      Edit
                    </MenuItem>,
                  ]
                : null
            }
          />
        </TableCell>
      </TableRow>
      <ConditionForm
        patient={patient}
        appointment={data}
        open={openModalEdit}
        onClose={() => setOpenModalEdit(false)}
        handleAppointment={handleAppointment}
      />
    </>
  );
}
