import { yupResolver } from '@hookform/resolvers/yup';
import { MobileDatePicker } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import produce from 'immer';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { OrganizationWrapper } from 'src/@nicheaim/fhir-base/wrappers/Organization';
import {
  PatientWrapper,
  WrappedPatient,
} from 'src/@nicheaim/fhir-base/wrappers/Patient';
import { ValueSetWrapper, WrappedValueSet } from 'src/@nicheaim/fhir-base/wrappers/ValueSet';
import { useOrganizations, usePatient, useValueSet } from 'src/@nicheaim/fhir-react';
import { Languages } from 'src/@types/crs/patient';
import GenderIdentityAutoComplete from 'src/components/GenderIdentityAutoComplete';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import {
  Coding,
  Extension,
  PatientGender,
  Reference,
  ValueSetComposeIncludeConcept,
} from 'src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources';
import Ethnicity from 'src/sections/crs/common/Ethnicity';
import Language from 'src/sections/crs/common/Language';
import OrganizationSelect from 'src/sections/crs/common/OrganizationSelect';
import Race from 'src/sections/crs/common/Race';
import { convertValueToValueSet, getExtension } from 'src/sections/crs/common/common-utils';
import { getGenderIdentityExtension } from 'src/utils/fhir';
import crsAcls from 'src/utils/permissions/crs/crsAcls';
import { checkAclValidation } from 'src/utils/permissions/permission.utils';
import * as Yup from 'yup';
import { FormProvider, RHFSelect, RHFTextField } from '../../../../../components/hook-form';
import useTenantConfigData from 'src/hooks/useTenantConfigData';
import { isEmpty } from 'lodash';

type FormValue = {
  lastName: string;
  middleName: string;
  firstName: string;
  birthDate: Date;
  gender: string;
  ethnicity: string[];
  race: string[];
  language: Languages[];
  ethnicityArray: any;
  raceArray: any;
  genderIdentity: ValueSetComposeIncludeConcept | null;
  managingOrganizationRef: Reference | null;
};

type Props = {
  patient: WrappedPatient;
  open: boolean;
  user: any;
  onClose: VoidFunction;
  i18n: any;
  genderIdentityVS: WrappedValueSet | null;
  showGender?: boolean;
  configurations: any
};

const EditMode = ({ i18n, patient, open, onClose, genderIdentityVS, showGender, configurations}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [, { update }] = usePatient(patient.id!, { map: PatientWrapper });
  const auth = useAuth();

  const [ethnicityCategories] = useValueSet('omb-ethnicity-category', { map: ValueSetWrapper });
  const [raceCategories] = useValueSet('omb-race-category', { map: ValueSetWrapper });
  const [simpleLanguage] = useValueSet('simple-language', { map: ValueSetWrapper });

  const URLS_RACE = ['ValueSet/omb-race-category', configurations?.fhir?.raceUrl];
  const URLS_ETHNICITY = ['ValueSet/omb-ethnicity-category', configurations?.fhir?.ethnicityUrl];
  const URLS_GENDER_IDENTITY = [
    'ValueSet/us-core-birthsex', 
    configurations?.fhir?.genderIdentityExtensionDefinitionUrl, 
    configurations?.fhir?.genderIdentityCodeSystemUrl
  ];

  const userOrgId = useMemo(() => {
    const org = auth.getSelectedOrg();
    const splitArry = org.fhir_uri.split('/');
    return splitArry[splitArry.length - 1];
  }, [auth]);

  const [organizations] = useOrganizations({
    filter: { _id: userOrgId, '_revinclude:iterate': 'Organization:partof' },
    map: OrganizationWrapper,
  });

  const defaultValues = useMemo(
    () =>
      ({
        lastName: patient?.name?.[0].family || '',
        firstName: patient?.name?.[0].given?.[0] || '',
        middleName: patient?.name?.[0].given?.[1] || '',
        birthDate: patient?.birthDate
          ? moment.utc(new Date(patient?.birthDate)).format('MM/DD/YYYY')
          : new Date(),
        gender: patient?.gender || '',
        ethnicity: patient.getEthnicityArray(configurations?.fhir)?.map((e: Coding) => e.display),
        race: patient.getRaceArray(configurations?.fhir)?.map((e: Coding) => e.display),
        ethnicityArray: patient.getEthnicityArray(configurations?.fhir),
        raceArray: patient.getRaceArray(configurations?.fhir),
        language: patient?.getLanguage() || [],
        genderIdentity: patient?.getGenderIdentityCodeableConcept?.(configurations?.fhir) ?? null,
        managingOrganizationRef: patient?.managingOrganization || null,
      } as FormValue),
    [patient, configurations]
  );

  const EventSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First Name is required')
      .matches(/^[a-zA-Z\s]+$/, 'First Name cannot contain numbers')
      .max(25, 'First Name must be a maximum of 25 characters'),
    lastName: Yup.string()
      .required('Last Name is required')
      .matches(/^[a-zA-Z\s-]+$/, 'Last Name cannot contain numbers')
      .max(25, 'Last Name must be a maximum of 25 characters'),
    middleName: Yup.string()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(/^[a-zA-Z\s]+$/, 'Middle Name cannot contain numbers')
      .max(25, 'Middle Name must be a maximum of 25 characters'),
    birthDate: Yup.date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required('Date of birth is required')
      .test('date_of_birth_test', 'Date of birth cannot be a date in the future', (value) => {
        const birthDate = moment(value ?? null);
        if (birthDate?.isSameOrAfter(moment())) {
          // @ts-ignore
          setError('birthDate', {
            message: 'Date of birth cannot be a date in the future',
            type: 'manual',
          });
          return false;
        }
        return true;
      }),
    language: Yup.lazy((val) =>
      Array.isArray(val)
        ? Yup.array().of(
            Yup.object().shape({
              value: Yup.string().min(2).required('Language is required'),
            })
          )
        : Yup.object().shape({
            value: Yup.string().min(2).required('Language is required'),
          })
    ),
  });

  const methods = useForm({ resolver: yupResolver(EventSchema), defaultValues });

  const { reset, control, handleSubmit, setError, setValue, getValues } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [open, defaultValues]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (data: FormValue) => {
    try {
      const dataRace = data?.raceArray;

      const raceSystemUrl = URLS_RACE[0];
      const codeValueRace =
        data?.raceArray?.length > 0 && raceSystemUrl
          ? getExtension(dataRace, raceCategories, raceSystemUrl, URLS_RACE[1])
          : [];

      const dataEthnicity = data?.ethnicityArray;

      const ethnicitySystemUrl = URLS_ETHNICITY[0];
      const codeValueEthnicity =
        data?.ethnicityArray?.length > 0 && ethnicitySystemUrl
          ? getExtension(dataEthnicity, ethnicityCategories, ethnicitySystemUrl, URLS_ETHNICITY[1])
          : [];

      let codeValueLanguage: any = data?.language?.map((e: Languages) => {
        const coding = convertValueToValueSet(e.value || '', simpleLanguage);
        const communication = {
          language: { coding: [coding], text: coding?.display },
          preferred: e.preferred,
        };
        return communication;
      });

      const genderIdentity = data.genderIdentity;

      const genderIdentityExtension: Extension[] =
        genderIdentity?.code && genderIdentity?.display
          ? [
              {
                ...getGenderIdentityExtension({
                  ...(genderIdentity as Coding),
                  system: genderIdentity?.system?.includes('administrative-gender')
                    ? genderIdentity?.system
                    : genderIdentityVS?.getConceptSystem?.(),
                },
                configurations?.fhir
              ),
              },
            ]
          : [];

      let otherExtension =
        patient?.extension?.filter(
          (e: any) =>
            ![...URLS_ETHNICITY, ...URLS_RACE, ...URLS_GENDER_IDENTITY]?.some(
              (s: any) => s === e?.url
            )
        ) || [];

      let extension: any = [
        ...otherExtension,
        ...(codeValueRace && codeValueRace),
        ...(codeValueEthnicity && codeValueEthnicity),
        ...genderIdentityExtension,
      ].filter((e: any) => e !== null);

      await update(
        produce(patient!, (draft) => {
          draft.name ??= [];
          draft.name[0] ??= {};
          draft.name[0].family = data.lastName;
          draft.name[0].given ??= [];
          draft.name[0].given = [
            data.firstName, 
            ...(!isEmpty(data.middleName) ? [data.middleName] : []),
          ];
          draft.birthDate = moment.utc(new Date(data.birthDate)).format('YYYY-MM-DD');
          draft.gender = data.gender as PatientGender;
          draft.extension = [...extension];
          draft.communication = codeValueLanguage;
          draft.managingOrganization = data.managingOrganizationRef || undefined;
        })
      );

      handleClose();
      enqueueSnackbar(`${i18n('patients.title', 'crs')} was updated.`);
    } catch {
      handleClose();
      enqueueSnackbar(`${i18n('patients.title', 'crs')} was not updated.`, { variant: 'error' });
    }
  };
  if (!open) return null;

  return (
    <Dialog keepMounted={false} open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogTitle>{i18n('patients.details.personalDetails.title', 'crs')}</DialogTitle>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ m: 2 }}>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2">{`${i18n('patients.lastName', 'crs')}*`}</Typography>
                  <RHFTextField name="lastName" label="" />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2">{i18n('patients.middleName', 'crs')}</Typography>
                  <RHFTextField name="middleName" label="" />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2">{`${i18n('patients.firstName', 'crs')}*`}</Typography>
                  <RHFTextField name="firstName" label="" />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2">{`${i18n('patients.dateOfBirth', 'crs')}*`}</Typography>
                  <Controller
                    name="birthDate"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <MobileDatePicker
                        {...field}
                        label=""
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!!error?.message}
                            helperText={error?.message}
                            fullWidth
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="body2">{`${i18n('patients.gender', 'crs')}*`}</Typography>
                  <RHFSelect name="gender" label="" fullWidth>
                    <MenuItem disabled/>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                    <MenuItem value="unknown">Unknown</MenuItem>
                  </RHFSelect>
                </Grid>

                {showGender && (
                  <Grid item xs={6}>
                    <Typography variant="body2">{i18n('patients.genderIdentity', 'crs')}</Typography>
                    <GenderIdentityAutoComplete
                      label=""
                      value={getValues('genderIdentity')}
                      onValueChange={(gender) => {
                        setValue('genderIdentity', gender);
                      }}
                      genderIdentityVS={genderIdentityVS}
                      isLoading={!genderIdentityVS}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {checkAclValidation({
                    acls: [crsAcls.CRS.PATIENT.MANAGING_ORGANIZATION.EDIT],
                  }) && (
                    <>
                      <Typography variant="body2">
                        {`${i18n('patients.managingOrganization', 'crs')}*`}
                      </Typography>
                      <OrganizationSelect
                        organizations={organizations}
                        selectedOrganizationRef={getValues('managingOrganizationRef')}
                        onValueChange={(value) => {
                          setValue('managingOrganizationRef', value);
                        }}
                        isLoading={!organizations}
                      />
                      <Alert severity="warning">
                        {i18n('patients.managingOrganizationEditWarning', 'crs')}
                      </Alert>
                    </>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Ethnicity ethnicityCategories={ethnicityCategories} />
                </Grid>

                <Grid item xs={12}>
                  <Race raceCategories={raceCategories} />
                </Grid>

                <Grid item xs={12}>
                  <Language simpleLanguage={simpleLanguage} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Stack spacing={2} alignItems="center">
            <DialogActions>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" color="info" onClick={handleClose}>
                {i18n('cancel')}
              </Button>
              {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.PERSONAL_DETAILS.EDIT] }) && (
                <Button variant="contained" color="info" type="submit">
                  {i18n('submit')}
                </Button>
              )}
            </DialogActions>
          </Stack>
        </Card>
      </FormProvider>
    </Dialog>
  );
};

type DisplayModeProps = {
  patient: WrappedPatient;
  i18n: any;
  showGender?: boolean;
  configurations: any
};

const DisplayMode = ({ patient, i18n, showGender, configurations }: DisplayModeProps) => (
  <Box sx={{ flexGrow: 1 }}>
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ p: 1 }}>
      <Grid item xs={3}>
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.lastName', 'crs')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.name?.[0]?.family}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.middleName', 'crs')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.name?.[0]?.given?.[1]}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.firstName', 'crs')}
        </Typography>
        <Typography variant="body2"> {patient?.name?.[0]?.given?.[0]}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.dateOfBirth', 'crs')}
        </Typography>
        <Typography variant="body2">
          {patient.birthDate ? `${moment(patient.birthDate).format('MMM D Y')}` : 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.gender', 'crs')}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.gender}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.details.personalDetails.race', 'crs')}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            textTransform: 'capitalize',
          }}
        >
          {patient?.getRace(configurations?.fhir)?.display}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.details.personalDetails.ethnicity', 'crs')}
        </Typography>
        <Typography variant="body2">{patient?.getEthnicity(configurations?.fhir)?.display}</Typography>
      </Grid>
      {showGender && (
        <Grid item xs={3}>
          <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
            {i18n('patients.genderIdentity', 'crs')}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {patient?.getGenderIdentityCodeableConcept?.(configurations?.fhir)?.display ?? ''}
          </Typography>
        </Grid>
      )}
      <Grid item xs={3}>
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.details.personalDetails.language', 'crs')}
        </Typography>
        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
          {patient?.getLanguage()?.[0]?.value}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.details.personalDetails.ageGroup', 'crs')}
        </Typography>
        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
          {patient?.getAgeGroup()}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" sx={{ color: 'text.secondary', py: 1, fontWeight: 'bold' }}>
          {i18n('patients.details.personalDetails.organization', 'crs')}
        </Typography>
        <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
          {patient?.getOrganization()}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);
type PropDetails = {
  patient: WrappedPatient;
  genderIdentityVS: WrappedValueSet | null;
};

export default function PersonalDetails({ patient, genderIdentityVS }: PropDetails) {
  const { i18n } = useLocales();
  const { componentsData, configurations } = useTenantConfigData();
  const { showGender } = componentsData ?? {};

  const user = useAuth();
  const [openEditPersonalDetails, setEditPersonalDetails] = useState(false);

  const handleCloseEditPersonalDetails = () => {
    setEditPersonalDetails(false);
  };

  return (
    <>
      <Stack direction="row" style={{ display: 'flex' }}>
        <Typography variant="button" sx={{ textTransform: 'uppercase', mt: 1 }}>
          {i18n('patients.details.personalDetails.title', 'crs')}
        </Typography>
        {checkAclValidation({ acls: [crsAcls.CRS.PATIENT.PERSONAL_DETAILS.EDIT] }) && (
          <IconButton onClick={() => setEditPersonalDetails(true)} sx={{ p: 0.5, ml: 1 }}>
            <Iconify icon={openEditPersonalDetails ? 'eva:save-outline' : 'eva:edit-outline'} />
          </IconButton>
        )}
      </Stack>
      <DisplayMode 
        i18n={i18n} 
        patient={patient}
        showGender={showGender}
        configurations={configurations}
      />
      <EditMode
        i18n={i18n}
        patient={patient}
        open={openEditPersonalDetails}
        user={user}
        onClose={handleCloseEditPersonalDetails}
        genderIdentityVS={genderIdentityVS}
        showGender={showGender}
        configurations={configurations}
      />
    </>
  );
}
