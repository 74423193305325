import moment from "moment";
import { 
  ColorIndicators, 
  Item, 
  MeasureReportCustom, 
  MeasureReportGroups, 
  QuestionnaireAnswerMap, 
  QuestionnaireResponseCustom, 
  RiskColor, 
  RiskColorPalette, 
  Risks, 
  Stratifier 
} from "src/@types/nat/report";
import { WrappedTask } from "src/@nicheaim/fhir-base/wrappers/Task";
import { Questionnaire } from "src/@nicheaim/fhir-base/mappings/Questionnaire";
import { RelatedPersonWrapper } from "src/@nicheaim/fhir-base/wrappers/RelatedPerson";
import { WrappedPlanDefinition } from "src/@nicheaim/fhir-base/wrappers/PlanDefinition";
import { WrappedServiceRequest } from "src/@nicheaim/fhir-base/wrappers/ServiceRequest";
import { 
  MeasureReport, 
  MeasureReportGroup, 
  MeasureReportGroupStratifier, 
  MeasureReportGroupStratifierStratum, 
  QuestionnaireResponseItem, 
  QuestionnaireResponseItemAnswer 
} from "src/nicheaim-infrastructure/application/adapters/out/repositories/fhir/resources";
import { PatientWrapper, WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { QuestionnaireResponse } from "src/@nicheaim/fhir-base/mappings/QuestionnaireResponse";
import { getMeasureReportGroups, getNPIFromIdentifier, getSplitReference, getStratumData } from "src/utils/fhir";

export const indicatorsList: ColorIndicators[] = [
  { text: "None", riskValue: "none", flex: 2 },
  { text: "Low Risk", riskValue: "low-risk", flex: 2 },
  { text: "Medium Risk", riskValue: "medium-risk", flex: 2 },
  { text: "High Risk", riskValue: "high-risk", flex: 2 },
  { text: "Indeterminate", riskValue: "indeterminate-risk", flex: 2 },
];

export const getRiskColor = (value: Risks) => {
  const riskColorBackgroundClassPrefix = "measure-report-background-indicator-";
  const riskColorNormalClassPrefix = "measure-report-indicator-";
  let riskColor: RiskColor = {
    backgroundClass: riskColorBackgroundClassPrefix + "indeterminate-risk",
    normalClass: riskColorNormalClassPrefix + "indeterminate-risk",
  };

  switch (value) {
    case "indeterminate-risk":
      riskColor.backgroundClass =
        riskColorBackgroundClassPrefix + "indeterminate-risk";
      riskColor.normalClass = riskColorNormalClassPrefix + "indeterminate-risk";
      break;
    case "none":
      riskColor.backgroundClass = riskColorBackgroundClassPrefix + "none";
      riskColor.normalClass = riskColorNormalClassPrefix + "none";
      break;
    case "low-risk":
      riskColor.backgroundClass = riskColorBackgroundClassPrefix + "low-risk";
      riskColor.normalClass = riskColorNormalClassPrefix + "low-risk";
      break;
    case "medium-risk":
      riskColor.backgroundClass =
        riskColorBackgroundClassPrefix + "medium-risk";
      riskColor.normalClass = riskColorNormalClassPrefix + "medium-risk";
      break;
    case "high-risk":
      riskColor.backgroundClass = riskColorBackgroundClassPrefix + "high-risk";
      riskColor.normalClass = riskColorNormalClassPrefix + "high-risk";
      break;
  }

  return riskColor;
};

export const getRisk = (riskText: string): Risks => {
  let casted: Risks = "none";

  switch (riskText) {
    case "none":
      casted = "none";
      break;
    case "low-risk":
      casted = "low-risk";
      break;
    case "medium-risk":
      casted = "medium-risk";
      break;
    case "high-risk":
      casted = "high-risk";
      break;
    case "high risk":
      casted = "high-risk";
      break;
    case "indeterminate-risk":
      casted = "indeterminate-risk";
      break;
    default:
      casted = "none";
  }

  return casted;
};

const getPrintableQuestionnaireResponseItems = (
  questResItem:
    | QuestionnaireResponseItem
    | QuestionnaireResponseItem[],
  questionnaireId: string, 
  questionnaireAnswerMap: QuestionnaireAnswerMap[] | undefined
) => {
  let printableItems: Item[] = [];
  if (Array.isArray(questResItem)) {
    questResItem.forEach((item: QuestionnaireResponseItem) => {
      const rList = getPrintableQuestionnaireResponseItems(
        item,
        questionnaireId,
        questionnaireAnswerMap
      );
      const mergedList = printableItems.concat(rList);
      printableItems = mergedList;
    });
  } else {
    if (questResItem.answer) {
      let printableItem: Item = {
        text: questResItem.text === undefined ? "" : questResItem.text,
        response: "",
        responseExplanation: "",
      };

      questResItem.answer?.forEach(
        (item: QuestionnaireResponseItemAnswer, index: number) => {
          if (index > 0) {
            printableItem.response += ", ";
          }

          let answerMap = questionnaireAnswerMap && 
            questionnaireAnswerMap?.find((e: QuestionnaireAnswerMap) => e?.questionId === questResItem.linkId);

          if (answerMap) {
            printableItem.responseExplanation = answerMap?.answerDisplay ?? "";
          }

          if (item.valueInteger !== undefined) {
            printableItem.response += String(item.valueInteger);
          } else if (item.valueCoding !== undefined) {
            printableItem.response += String(item.valueCoding.display);
          } else if (item.valueString !== undefined) {
            printableItem.response += item.valueString;
          }
        }
      );

      printableItems.push(printableItem);
    }

    if (questResItem.item) {
      const rList = getPrintableQuestionnaireResponseItems(
        questResItem.item,
        questionnaireId,
        questionnaireAnswerMap
      );
      const mergedList = printableItems.concat(rList);
      printableItems = mergedList;
    }
  }

  return printableItems;
};

const getStratifiersNQuestsFromMRGroup = (
  group: MeasureReportGroup, 
  questionnaireResponse: QuestionnaireResponse[],
  questionnaire: Questionnaire[],
  questionnaireAnswerMap: QuestionnaireAnswerMap[],
  showMeasureQR: boolean
) => {

  const questResponse = questionnaireResponse?.
    find((item) => item?.id === getSplitReference(
      group.code?.coding?.[0].code ?? '')?.uuid);

  const matchingQuestUuidFromUrl = (() => {
    if (questResponse?.questionnaire === undefined) return undefined;
  
    const url = questResponse?.questionnaire;
    const pathParts = url.split('/');
    const lastPart = pathParts[pathParts.length - 1];
  
    return lastPart;
  })();

  const questionnaireFromQuest = questionnaire?.find((item) => item?.id === matchingQuestUuidFromUrl);

  let printableStratifiers: Stratifier[] = [];

  group.stratifier?.forEach((item: MeasureReportGroupStratifier) => {
    item.stratum?.forEach((item2: MeasureReportGroupStratifierStratum) => {
      printableStratifiers.push(getStratumData(item2));
    });
  });

  let printableMeasureReportGroup: MeasureReportCustom = {
    stratum_text: group.code?.text === undefined ? "" : group.code?.text,
    stratifiers: printableStratifiers,
  };

  let printableQuestionnaireResponse: QuestionnaireResponseCustom = {
    text:
    questionnaireFromQuest?.title === undefined
        ? ""
        : questionnaireFromQuest?.title,
    items:
    questResponse?.item === undefined || questResponse?.id === undefined
        ? []
        : getPrintableQuestionnaireResponseItems(
          questResponse?.item, 
          questionnaireFromQuest?.id ?? '',
          questionnaireAnswerMap
        ),
  };

  if (showMeasureQR) {
    printableMeasureReportGroup.questionnaire_response = printableQuestionnaireResponse;
  }

  return printableMeasureReportGroup;
};

export const getPrintableMeasureReportGroups = (
  groups: MeasureReportGroup[],
  questionnaireResponse: QuestionnaireResponse[],
  questionnaire: Questionnaire[],
  questionnaireAnswerMap: QuestionnaireAnswerMap[],
  showMeasureQR: boolean
) => {
  let printableMeasureGroup: MeasureReportCustom[] = [];

  groups.forEach((item: MeasureReportGroup) => {
    printableMeasureGroup.push(
      getStratifiersNQuestsFromMRGroup(
        item,
        questionnaireResponse,
        questionnaire,
        questionnaireAnswerMap,
        showMeasureQR
      ));
  });

  return printableMeasureGroup;
};

export const getPrintableQuestionnaireResponseList = (
  questionnaire: Questionnaire[] | undefined,
  questionnaireAnswerMap: QuestionnaireAnswerMap[] | undefined,
  questionnaireResponse: QuestionnaireResponse[] | undefined
) => {
  let printableQuestionnaireResponseList: QuestionnaireResponseCustom[] = [];

  questionnaireResponse?.map((item) => {

    const matchingQuestUuidFromUrl = (() => {
      if (item?.questionnaire === undefined) return undefined;
    
      const url = item?.questionnaire;
      const pathParts = url.split('/');
      const lastPart = pathParts[pathParts.length - 1];
    
      return lastPart;
    })();
    
    const questionnaireFromQuest = questionnaire?.find((item) => item?.id === matchingQuestUuidFromUrl);
    
    const printableQuestionnaireResponse: QuestionnaireResponseCustom = {
      text:
      questionnaireFromQuest?.title === undefined
          ? ""
          : questionnaireFromQuest?.title,
      items:
        item?.item === undefined || questionnaireFromQuest?.id === undefined
          ? []
          : getPrintableQuestionnaireResponseItems(
            item?.item, 
            questionnaireFromQuest?.id,
            questionnaireAnswerMap
          ),
    };
    printableQuestionnaireResponseList.push(printableQuestionnaireResponse);
  });

  return printableQuestionnaireResponseList;
};

export const getRiskColorPalette = (value: Risks) => {

  let riskColorPalette: RiskColorPalette = {
    backgroundColor: "#f57c00",
    internalBackgroundColor: "#f57c00",
    textColor: "#000000",
  };

  switch (value) {
    case "indeterminate-risk":
      riskColorPalette.backgroundColor = "#9c27b0";
      riskColorPalette.internalBackgroundColor = "#9c27b0";
      riskColorPalette.textColor = "#ffffff";
      return riskColorPalette;
    case "none":
      riskColorPalette.backgroundColor = "#f57c00";
      riskColorPalette.internalBackgroundColor = "#f57c00";
      riskColorPalette.textColor = "#000000";
      return riskColorPalette;
    case "low-risk":
      riskColorPalette.backgroundColor = "#229a16";
      riskColorPalette.internalBackgroundColor = "#229a16";
      riskColorPalette.textColor = "#ffffff";
      return riskColorPalette;
    case "medium-risk":
      riskColorPalette.backgroundColor = "#ffc107";
      riskColorPalette.internalBackgroundColor = "#ffc107";
      riskColorPalette.textColor = "#000000";
      return riskColorPalette;
    case "high-risk":
      riskColorPalette.backgroundColor = "#ff4842";
      riskColorPalette.internalBackgroundColor = "#ff4842";
      riskColorPalette.textColor = "#ffffff";
      return riskColorPalette;
    default:
      return riskColorPalette;
  }
};

interface PrintableTask {
  patient: WrappedPatient | undefined;
  task: WrappedTask | undefined;
  serviceRequest: WrappedServiceRequest | null;
  planDefinition: WrappedPlanDefinition | null;
  questionnaire: Questionnaire[] | undefined;
  questionnaireAnswerMap: QuestionnaireAnswerMap[] | undefined;
  questionnaireResponse: QuestionnaireResponse[] | undefined;
  measureReport: MeasureReport | undefined;
  ownerTask: any;
  requesterTask: any;
};

export const getPrintableTask = ( body: PrintableTask, showMeasureQR: boolean, configurations: any) => {
  const { 
    patient, 
    task, 
    serviceRequest, 
    planDefinition, 
    questionnaire, 
    questionnaireAnswerMap,
    questionnaireResponse, 
    measureReport,
    ownerTask,
    requesterTask
  } = body;

  const mrn = patient?.getMRN(configurations?.fhir?.systemMRNNAT)?.value ?
    patient?.getMRN(configurations?.fhir?.systemMRNNAT)?.value :
    patient?.getMRN(configurations?.mpi?.systemMrn)?.value;

  const taskNumber = task 
    ? task?.getInternalNumber?.(configurations?.fhir?.taskInternalNumberIdentifierSystem) 
      ?? task?.getTaskNumberNAT(configurations?.fhir?.taskNATIdenitifierType) 
      ?? ""
    : "";
    
  const setPatient = {
    name: patient?.getFullName() ?? "",
    birth_date: patient?.birthDate ? moment.utc(new Date(patient?.birthDate)).format('MMMM D Y') : "",
    age: patient?.birthDate ? `${patient.getAgeInYears()} years` : "",
    mrn: mrn ?? "",
    gender: patient?.gender ?? "",
    phone: patient?.getPrimaryPhone()?.value ?? "",
    email: patient?.getPrimaryEmail()?.value ?? "",
    race: patient?.getRace(configurations?.fhir)?.display ?? "",
    ethnicity: patient?.getEthnicity(configurations?.fhir)?.display ?? "",
    language: patient?.getLanguage()?.[0]?.value ?? "",
  };

  const setOwner = ownerTask && ownerTask?.resourceType === 'Patient' ?  
    PatientWrapper(ownerTask) : RelatedPersonWrapper(ownerTask);

  const request = {
    req_date: serviceRequest?.authoredOn
      ? new Date(serviceRequest?.authoredOn).toDateString()
      : "",
    req_status: serviceRequest?.status ?? "",
    req_plan: planDefinition?.title ?? "",
    task_no: taskNumber ?? "",
    task_status: task?.status ?? "",
    task_priority: task?.priority ?? "",
    owner_type: setOwner?.resourceType === 'Patient' ? 'Beneficiary' : 'Parent/Legal Guardian',
    owner_name: setOwner?.getFullName() ?? "",
    owner_phone: setOwner?.getPrimaryPhone()?.value ?? "",
    owner_email: setOwner?.getPrimaryEmail()?.value ?? "",
  };

  const requester = {
    type: requesterTask?.resource?.resourceType,
    name: (requesterTask?.resource?.resourceType === 'Practitioner' ? 
      `${requesterTask?.resource?.name?.[0].given} ${requesterTask?.name?.[0].family}` :
      requesterTask?.resource?.name) ?? "",
    npi: requesterTask?.resource?.identifier ? getNPIFromIdentifier(requesterTask?.resource?.identifier) : "",
    phone: requesterTask?.resource?.telecom?.find((item: any) => item?.system === 'phone')?.value ?? "",
    email: requesterTask?.resource?.telecom?.find((item: any) => item?.system === 'email')?.value ?? "",
    created_by: task?.identifier?.find((item: any) => item?.type?.text === 'User')?.value ?? "",
  };

  const mrGroups: MeasureReportGroups = getMeasureReportGroups(measureReport, configurations?.fhir);
  const measureReportGroups = {
    measure_report: mrGroups.normal === undefined || questionnaireResponse === undefined || questionnaire === undefined ? [] : 
      getPrintableMeasureReportGroups(
        mrGroups.normal,
        questionnaireResponse,
        questionnaire,
        questionnaireAnswerMap ?? [],
        showMeasureQR
      )
  };

  const questionnaire_response_list = getPrintableQuestionnaireResponseList(
    questionnaire,
    questionnaireAnswerMap ?? [],
    questionnaireResponse,
  );

  return {
    patient: setPatient, 
    request, 
    requester, 
    ...measureReportGroups, 
    questionnaire_response_list,
    logo: configurations?.appUrl + "/static/brand/logo.png"
  }
}