import moment from "moment";
import { getValueSetConceptValue } from "src/sections/crs/helpers/common";
import { ReferralPayload } from "src/sections/crs/referral/components/child-referral/constants";
import { getDynamicValueExpressionByPath } from "src/utils/fhir";

export const mapNATRequestTask = ({
  patient,
  planDefinition,
  activityDefinition,
  requestActive,
  recipient,
  taskPriorities,
  taskIntents,
  taskStatuses
}: any) => {
  const request: ReferralPayload | null = {
    id: null,
    date: moment().toISOString(),
    start: moment().format('HH:mm'),
    status: planDefinition?.serviceRequestStatus?.code,
    intent: planDefinition?.serviceRequestIntent?.code,
    patientId: patient?.id ?? null,
    referredFrom: patient?.managingOrganization
      ? {
      reference: patient?.managingOrganization?.reference,
      ...(patient?.managingOrganization?.display && 
        {display: patient?.managingOrganization?.display }),
      }
    : null,
    category: planDefinition?.topic?.length > 0 ? planDefinition?.topic : null,
    services: null,
    service: null,
    serviceId: recipient ? recipient?.value : null,
    serviceType: planDefinition ? 
        planDefinition?.serviceRequestCode?.value?.healthcareService : null,
    serviceName: recipient ? recipient?.label : null,
    instantiatesCanonical: planDefinition?.reference ? 
      [ planDefinition?.reference ] : null,
    parentServiceRequest: null,
  };

  const priority = getValueSetConceptValue(
    taskPriorities?.asList?.() ?? [],
    activityDefinition?.[0]?.priority
  );
  const intent = getValueSetConceptValue(
    taskIntents?.asList?.() ?? [],
    getDynamicValueExpressionByPath(activityDefinition?.[0]?.dynamicValue, 'task.intent')
  );
  const status = getValueSetConceptValue(
    taskStatuses?.asList?.() ?? [],
    getDynamicValueExpressionByPath(activityDefinition?.[0]?.dynamicValue, 'task.status')
  );

  const taskType = activityDefinition?.[0]?.code?.coding?.[0] ?? null;
  const task: any = {
    status: status?.code ?? null,
    intent: intent?.code ?? null,
    priority: priority?.code ?? null,
    taskType: taskType?.code ?? null,
    taskTypeDisplay: taskType?.display ?? null,
    ownerId: recipient ? recipient?.value : null,
    ownerDisplay: recipient ? recipient?.label : null,
    patientId: patient?.id,
    patientName: patient?.getFullName(),
    activityDefinitionId: activityDefinition?.[0]?.id,
    basedOn: requestActive ? [{
      reference: `${requestActive?.resourceType}/${requestActive?.id}`
    }] : undefined,
    description: taskType?.display ?? null,
    requesterId: patient?.managingOrganization ? 
      patient?.managingOrganization?.reference : null,
    requesterDisplay: patient?.managingOrganization?.display ? 
      patient?.managingOrganization?.display : null
  };

  return {
    request,
    task
  }
};