import {
  Autocomplete,
  Backdrop,
  Button, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import Iconify from 'src/components/Iconify';
import { patientService } from "src/engagement/patient";
import { WrappedPatient } from "src/@nicheaim/fhir-base/wrappers/Patient";
import { CreatePatientEngagementDto } from "src/engagement/patient/dto/create-patient-engagement.dto";
import useLocales from "src/hooks/useLocales";
import { checkAclValidation } from "src/utils/permissions/permission.utils";
import crsAcls from "src/utils/permissions/crs/crsAcls";
import agent from "src/api/agent";

type Props = {
  patient: WrappedPatient | null;
  handlerRefresh: Function;
};

export default function CreatePatientEngagement({
  patient,
  handlerRefresh
}: Props) {

  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLocales();
  const [openModal, setOpenModal] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const addPermission = checkAclValidation({ acls: [crsAcls.ENGAGEMENT_HUB.PATIENT_ENGAGEMENT.ADD] });
  const [workflows, setWorkflows] = useState<any>(null);
  const [selectedWorkflow, setSelectedWorkflow] = useState<any>();
  const [error, setError] = useState(false);
  
  const handlerCreatePatientEngagement = async () => {
    if(!selectedWorkflow && !selectedWorkflow?.code) {
      setError(true);
      return;
    };
    setOpenBackdrop(true);
    try {
      const payload: CreatePatientEngagementDto = {
        fhirId: patient?.id ?? '',
        language: patient?.getLanguage()?.[0]?.value ?? '',
        scope: selectedWorkflow?.code ?? ''
      };
  
      await patientService.createPatientEngagement(payload);
      enqueueSnackbar(`${i18n('patients.title', 'crs')} Engagement was created`);
      handlerRefresh();
    } catch (error) {
      console.log(error);
      enqueueSnackbar('An error has occurred', { variant: 'error' });
    } finally{
      setOpenModal(false);
      setOpenBackdrop(false);
    }
  };

  const getWorkflowsByType = async () => {
    const workflowsByType = await agent.Workflow.getWorkflowByType('ehub-box');
    setWorkflows(workflowsByType);
  };

  useEffect(() => {
    getWorkflowsByType();
  },[]);

  useEffect(() => {
    setError(false);
    setSelectedWorkflow(null);
  },[openModal]);

  return(
    <>
      {addPermission && (
        <Button
          disabled={false}
          sx={{ height: '36px' }}
          onClick={() => setOpenModal(true)}
          startIcon={<Iconify icon={'eva:plus-fill'} />}
          style={{ position: 'absolute', top: '25px', right: '25px' }}
        >
          Add {i18n('patients.title', 'crs')} Engagement
        </Button>
      )}

      <Dialog 
        open={openModal}
        fullWidth
        maxWidth="sm"
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>Add {i18n('patients.title', 'crs')} Engagement</DialogTitle>
        <DialogContent>
          <Stack direction="row" alignItems="center">
            <Typography mt={2.5}>Are you sure you want to create a new &nbsp;</Typography>
            <Autocomplete
              value={selectedWorkflow}
              sx={{ width: '48%'}}
              onChange={(_: React.SyntheticEvent, worflow) => {
                setError(false);
                setSelectedWorkflow(worflow);
              }}
              options={workflows ?? []}
              getOptionLabel={({ name }) => name ?? ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!error}
                  helperText={error && "Required"}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {!workflows?.length ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />?
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={() => setOpenModal(false)}>
            Disagree
          </Button>

          <Button variant="contained" onClick={handlerCreatePatientEngagement}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}